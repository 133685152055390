.p-header-nav {
  line-height: var(--line-height-xs);

  @media (--lg) {
    display: flex;
    justify-content: flex-end;
    font-size: var(--15px);
  }
}

.p-header-nav__inner {
  width: 100%;
}

.p-header-nav__items {
  margin: 0;
  padding: 0;
  list-style: none;

  @media (--lg) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media (--to-lg) {
    &.is-active {
    }
  }
}
.p-header-nav__item {
  @media (--to-lg) {
  }
  @media (--lg) {
    display: flex;
    justify-content: flex-end;
  }
}
.p-header-nav__title {
  position: relative;
  width: 100%;

  @nest html.is-gnav-hover-open & {
    @media (--lg) {
      z-index: 1;
    }
  }
}
.p-header-nav__link {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--color-type);
  font-size: var(--15px);
  @mixin onHoverFocus {
    opacity: var(--opacity);
  }

  @media (--to-lg) {
    position: relative;
    width: 100%;
    padding: 15px 10vw 15px var(--20px);
    font-size: var(--14px);
    border-bottom: 1px solid rgba(var(--color-white-rgb), 0.1);

    &::after {
      position: absolute;
      top: 50%;
      right: 5vw;
      display: inline-block;
      width: 1.25em;
      height: 1.25em;
      vertical-align: middle;
      background-color: currentColor;
      transform: translateY(-50%);
      content: "";
      mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M18.75 36 16.6 33.85 26.5 23.95 16.6 14.05 18.75 11.9 30.8 23.95Z"/></svg>');
      mask-repeat: no-repeat;
      mask-position: center;
    }

    &[aria-expanded] {
      &::after {
        transform: translateY(-50%) rotate(90deg);
      }
      &[aria-expanded="true"] {
        &::after {
          transform: translateY(-50%) rotate(-90deg);
        }
      }
    }
  }

  @media (--lg) {
    position: relative;
    justify-content: center;
    width: 100%;
    padding: 10px 20px;
    font-size: var(--15px);
    transition: color 0.15s, background 0.15s;

    & span {
      position: relative;
      display: inline-block;
      &.p-header-nav__button {
        display: block;
        padding: var(--space-2\.5) var(--space-5);
        color: #fff;
        background: #222;
        border-radius: var(--space-1);
      }
    }

    &[aria-expanded] {
      padding: 10px 10px;
      padding-left: 17px;

      & span {
        padding-right: 1.35rem;

        &::before {
          position: absolute;
          top: 50%;
          right: 0;
          display: inline-block;
          width: 1.25em;
          height: 1.25em;
          vertical-align: middle;
          background-color: currentColor;
          transform: translateY(-50%) rotate(90deg);
          content: "";
          mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>');
          mask-repeat: no-repeat;
          mask-position: center;
        }
      }
    }

    &.is-active {
      z-index: 1;
      color: var(--color-main-menu);

      &::after {
        opacity: 1;
      }

      &[aria-expanded] {
        & span {
          &::before {
            transform: translateY(-50%) rotate(-90deg);
          }
        }
      }

      @nest html.is-gnav-hover-open & {
        background: var(--color-white);
      }
    }

    @nest .p-header-nav__item.is-current & {
      &::after {
        opacity: 1;
      }
    }
  }
  & .icon {
    flex-shrink: 0;
    margin-left: 4px;
  }
  & span {
    font-weight: bold;
  }
}

.p-header-nav__child {
  position: relative;
  top: 100%;
  left: 0;
  display: none;
  width: 100%;

  @media (--lg) {
    position: absolute;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(var(--color-white-rgb), 1);
      content: "";
    }
  }
}
.p-header-nav__child-inner {
  width: 100%;
  max-width: none;
  padding: 8px 0;
  background-color: var(--color-gray-30);

  @media (--lg) {
    display: flex;
    padding: 40px 0;
    overflow: hidden;
    background-color: transparent;
  }
}

.p-header-nav__head {
  display: block;

  @media (--lg) {
    width: 260px;
    margin: 0 auto;
    margin-right: 40px;
    padding-right: 40px;
    border-right: 1px solid var(--color-border);
  }

  & h2 {
    font-size: inherit;
    line-height: inherit;
  }
}
.p-header-nav__head-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 5vw;
  font-weight: var(--font-normal);

  @media (--lg) {
    display: inline-block;
    margin-bottom: 15px;
    padding: 0 15px;
    padding: 0;
    color: var(--color-type);
    font-weight: var(--font-bold);
  }
}

.p-header-nav__head-link {
  display: block;
  padding: 8px 0;
  font-size: var(--14px);

  @mixin onHoverFocus {
    text-decoration: underline;
    &::after {
      animation-name: icon-arrow;
      animation-duration: 0.4s;
      animation-timing-function: var(--ease);
    }
  }

  @media (--lg) {
    position: relative;
    display: inline-block;
    padding: 0;
    padding-right: 27px;
    font-weight: var(--font-bold);
    font-size: var(--20px);
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-top: -9px;
      vertical-align: middle;
      background-color: currentColor;
      content: "";
      mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="m24 40-2.1-2.15L34.25 25.5H8v-3h26.25L21.9 10.15 24 8l16 16Z"/></svg>');
      mask-repeat: no-repeat;
      mask-position: center;
    }
  }
}
.p-header-nav__head-text {
  display: block;
  padding: 8px 0;
  font-size: var(--14px);
  @media (--lg) {
    display: inline-block;
    padding: 0;
    font-weight: var(--font-bold);
    font-size: var(--20px);
  }
}

.p-header-nav__body {
  padding: 0 5vw;

  @media (--lg) {
    flex: 1 0 0%;
    margin: 0 auto;
    padding: 0;
  }
}

.p-header-nav__child-items {
  padding: 0;
  list-style: none;

  &._indent {
    margin-left: 1em;
  }

  @media (--lg) {
    margin: -5px 0;
    padding: 0;

    &._inline {
      display: flex;
      flex-wrap: wrap;
      margin: -5px -20px;
    }
  }
}
.p-header-nav__child-item {
  @media (--lg) {
    margin: 0;
    padding: 5px 0;

    @nest .p-header-nav__child-items._inline & {
      padding: 5px 20px;
    }
  }
}
.p-header-nav__child-link {
  position: relative;
  display: block;
  padding: 8px 0;
  color: var(--color-type);
  font-size: var(--14px);

  & > span {
  }

  @media (--lg) {
    position: static;
    padding: 0;
    font-size: var(--16px);
  }
  & .icon {
    margin-left: 4px;
  }

  @mixin onHoverFocus {
    text-decoration: underline;
  }
}
