.c-textarea {
  width: 100%;
  height: 40px;
  padding: 0 0.8em;
  color: var(--color-type);
  line-height: var(--line-height-sm);
  background: var(--color-white);
  border: 1px solid var(--color-border);
  transition: border 0.2s;
  -webkit-appearance: none;
}

.c-textarea {
  min-height: 8em;
  padding: 0.8em;
  @media (--md) {
    min-height: 8em;
    &._high {
      min-height: 20em;
    }
  }
}

.c-textarea:disabled {
  color: var(--color-gray-60);
  background: var(--color-gray-20);
}

.c-textarea._error {
  background: var(--color-sub-20);
  border-color: var(--color-sub);
}
