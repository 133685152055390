[class*="c-divider"] {
  --margin: 0;
  height: 1px !important;
  margin: var(--margin);
  overflow: hidden !important;
  background-color: var(--color-border) !important;
  border: none !important;

  &._full {
    margin-right: calc(((100vw - 100%) / 2) * -1) !important;
    margin-left: calc(((100vw - 100%) / 2) * -1) !important;
    padding: 0 calc((100vw - 100%) / 2) !important;
  }
}

.c-divider {
  --margin: 20px 0 30px;
}

.c-divider-2 {
  --margin: 50px 0 30px;
}
