.l-main__outer {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100vh;
}

.l-main {
  overflow: hidden;
  overflow-wrap: break-word;
}

.l-main__header {
}

.l-main__body {
  /* padding-bottom: 50px;

  @media (--md) {
    padding-bottom: 100px;
  } */
}

.l-main__contents {
  /* padding-top: 32px;

  @media (--md) {
    padding-top: 48px;
  }
  @media (--lg) {
    padding-top: 64px;
  } */
}

.l-main__footer {
}
