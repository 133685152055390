.c-icon {
  width: 1em;
  height: 1em;
  font-size: inherit;
  vertical-align: middle;
  fill: currentcolor;
  & img,
  & svg {
    width: 1em;
    height: auto;
  }

  &._s {
    font-size: 0.8em !important;
  }

  &._l {
    font-size: 1.2em !important;
  }

  &._xl {
    font-size: 1.4em !important;
  }
}

.c-icon-font {
  display: inline-block;
  font-weight: inherit !important;
  font-size: 1em !important;
  font-style: normal;
  line-height: 1.2 !important;
  vertical-align: middle;
  &._s {
    font-size: 0.8em !important;
  }

  &._l {
    font-size: 1.2em !important;
  }

  &._xl {
    font-size: 1.4em !important;
  }
}
