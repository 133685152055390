ul.c-list {
  list-style-type: disc;
}
ol.c-list {
  list-style-type: decimal;
}

.c-list {
  padding: 0;
  padding-left: 1.4em;
  list-style-position: outside;

  &:where(:first-child) {
    margin-top: 0;
  }
  &:where(:last-child) {
    margin-bottom: 0;
  }

  &._style-none {
    padding-left: 0;
    text-indent: 0;
    list-style: none;
  }

  &._indent {
    padding-left: 1.2em;
    text-indent: -1.2em;
    list-style: none;
  }
  &._indent-2 {
    padding-left: 2.4em;
    text-indent: -2.4em;
    list-style: none;
  }

  &._style-disc,
  &._style-hyphen,
  &._style-asterisk,
  &._style-decimal-note,
  &._style-decimal,
  &._style-check {
    list-style-type: none;
  }

  &._style-hyphen,
  &._style-asterisk,
  &._style-decimal-note,
  &._style-decimal,
  &._style-check {
    & > li {
      position: relative;
      &::before {
        position: absolute;
        /* top: 0; */
        left: -1.2em;
        display: inline;
      }
    }
  }

  &._style-check {
    & > li {
      &::before {
        top: 0.2em;
        left: -1.2em;
        width: 1.1em;
        height: 1.1em;
        background-color: currentColor;
        content: "";
        mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z"/></svg>');
        mask-repeat: no-repeat;
        mask-position: center;
      }
    }
  }

  &._style-decimal-note,
  &._style-decimal {
    & > li {
      &::before {
        left: -1.2em;
      }
    }
  }

  &._style-disc {
    list-style-type: disc;
  }

  &._style-hyphen {
    & > li {
      &::before {
        left: -1.2em;
        content: "-";
      }
    }
  }
  &._style-asterisk {
    & > li {
      &::before {
        left: -1.2em;
        content: "*";
      }
    }
  }

  &._style-decimal {
    counter-reset: item;
    & > li {
      &::before {
        content: counter(item) ".";
        counter-increment: item;
      }

      & .c-list._style-decimal {
        counter-reset: item;
        & > li::before {
          content: "(" counter(item) ")";
          counter-increment: item;
        }
      }
    }
  }
  &._style-decimal-note {
    padding-left: 1.6em;
    counter-reset: item;

    & > li {
      & .decimal-num {
        position: absolute;
        left: -1.4em;
        display: inline;
      }
    }
  }

  &._border {
    & > li {
      & + li {
        border-top: 1px solid var(--color-border);
      }
    }
  }

  &._ib {
    margin-left: -15px;
    padding-left: 0;

    & > li {
      display: inline-block;
      margin-left: 15px;
    }
  }
}
