:root {
  --color-main-10: #eff6fc;
  --color-main-20: #deecf9;
  --color-main-30: #c7e0f4;
  --color-main-40: #2b88d8;
  --color-main-50: #0078d4;
  --color-main-60: #106ebe;
  --color-main-70: #005a9e;
  --color-main-80: #004578;
  --color-main-90: #003055;
  --color-main: var(--color-main-50);

  --color-sub-10: #fdf4e3;
  --color-sub-20: #f6e7c7;
  --color-sub-30: #f0d396;
  --color-sub-40: #e39f1a;
  --color-sub-50: #d48c00;
  --color-sub-60: #c28000;
  --color-sub-70: #aa7000;
  --color-sub-80: #845700;
  --color-sub-90: #533800;
  --color-sub: var(--color-sub-50);

  --color-white: #fff;
  --color-black: #222;

  --color-gray-10: #f8f8f8;
  --color-gray-20: #cccccc;
  --color-gray-30: #b3b3b3;
  --color-gray-40: #999999;
  --color-gray-50: #808080;
  --color-gray-60: #666666;
  --color-gray-70: #4d4d4d;
  --color-gray-80: #333333;
  --color-gray-90: #1a1a1a;
  --color-gray: var(--color-gray-50);

  /* ボーダー色 */
  --color-border: var(--color-gray-50);
  --color-border-light: var(--color-gray-30);
  --color-border-dark: var(--color-gray-70);
  --color-input-border: var(--color-gray-60);
  --color-input-border-hover: var(--color-gray-80);

  /* 通常テキスト色 */
  --color-type: var(--color-black);

  /* リンク色 */
  --color-link: var(--color-main-70);

  /*================================================================
    RGB版
  ================================================================ */
  --color-main-10-rgb: 239, 246, 252;
  --color-main-20-rgb: 222, 236, 249;
  --color-main-30-rgb: 199, 224, 244;
  --color-main-40-rgb: 43, 136, 216;
  --color-main-50-rgb: 0, 120, 212;
  --color-main-60-rgb: 16, 110, 190;
  --color-main-70-rgb: 0, 90, 158;
  --color-main-80-rgb: 0, 69, 120;
  --color-main-90-rgb: 0, 48, 85;
  --color-main-rgb: var(--color-main-50-rgb);

  --color-sub-10-rgb: 253, 244, 227;
  --color-sub-20-rgb: 246, 231, 199;
  --color-sub-30-rgb: 240, 211, 150;
  --color-sub-40-rgb: 227, 159, 26;
  --color-sub-50-rgb: 212, 140, 0;
  --color-sub-60-rgb: 194, 128, 0;
  --color-sub-70-rgb: 170, 112, 0;
  --color-sub-80-rgb: 132, 87, 0;
  --color-sub-90-rgb: 83, 56, 0;
  --color-sub-rgb: var(--color-sub-50-rgb);

  --color-white-rgb: 255, 255, 255;
  --color-black-rgb: 0, 0, 0;

  --color-gray-10-rgb: 245, 245, 245;
  --color-gray-20-rgb: 204, 204, 204;
  --color-gray-30-rgb: 179, 179, 179;
  --color-gray-40-rgb: 153, 153, 153;
  --color-gray-50-rgb: 128, 128, 128;
  --color-gray-60-rgb: 102, 102, 102;
  --color-gray-70-rgb: 77, 77, 77;
  --color-gray-80-rgb: 51, 51, 51;
  --color-gray-90-rgb: 26, 26, 26;
  --color-gray-rgb: var(--color-gray-50-rgb);

  --color-yellow: #ffc600;
}
