[class^="l-stack"],
[class*=" l-stack"] {
  & > * + * {
    margin-top: var(--stack-gap) !important;
  }
}

@media (--to-md) {
  [class^="s:l-stack"],
  [class*=" s:l-stack"] {
    & > * + * {
      margin-top: var(--stack-gap) !important;
    }
  }
}
@media (--md) {
  [class^="m:l-stack"],
  [class*=" m:l-stack"] {
    & > * + * {
      margin-top: var(--stack-gap) !important;
    }
  }
}
@media (--lg) {
  [class^="l:l-stack"],
  [class*=" l:l-stack"] {
    & > * + * {
      margin-top: var(--stack-gap) !important;
    }
  }
}

@define-mixin l-stack $responsive {
  $(responsive)-stack > * + * {
    --stack-gap: var(--space-4);
  }
  $(responsive)-stack-0 > * + * {
    --stack-gap: 0;
  }
  $(responsive)-stack-px > * + * {
    --stack-gap: 1px;
  }
  $(responsive)-stack-1 > * + * {
    --stack-gap: var(--space-1);
  }
  $(responsive)-stack-2 > * + * {
    --stack-gap: var(--space-2);
  }
  $(responsive)-stack-3 > * + * {
    --stack-gap: var(--space-3);
  }
  $(responsive)-stack-4 > * + * {
    --stack-gap: var(--space-4);
  }
  $(responsive)-stack-6 > * + * {
    --stack-gap: var(--space-6);
  }
  $(responsive)-stack-8 > * + * {
    --stack-gap: var(--space-8);
  }
  $(responsive)-stack-10 > * + * {
    --stack-gap: var(--space-10);
  }
  $(responsive)-stack-12 > * + * {
    --stack-gap: var(--space-12);
  }
  $(responsive)-stack-16 > * + * {
    --stack-gap: var(--space-16);
  }
  $(responsive)-stack-24 > * + * {
    --stack-gap: var(--space-24);
  }
}

@mixin l-stack .l;
@media (--to-md) {
  /* prettier-ignore */
  @mixin l-stack .s\:l;
}
@media (--md) {
  /* prettier-ignore */
  @mixin l-stack .m\:l;
}
@media (--lg) {
  /* prettier-ignore */
  @mixin l-stack .l\:l;
}
