.c-card-media {
  position: relative;
  & img {
    width: 100%;
  }
}

a .c-card-media {
  overflow: hidden;
  & img {
    backface-visibility: hidden;
    transition: transform 0.3s var(--ease);
    will-change: transform;
  }
}
a {
  @mixin onHoverFocus {
    & .c-card-media img {
      transform: scale(var(--scale));
    }
  }
}
