input[type="radio"] {
  accent-color: var(--color-main);
}

.c-radio {
  display: inline-block;
  margin: 0;
  font-weight: normal;
  line-height: var(--line-height-sm);
  border-radius: 0;
  box-shadow: none;

  & .label-text {
    display: inline-block;
    padding-left: 0.5em;
    color: var(--color-type);
    line-height: var(--line-height-sm);
    vertical-align: middle;
  }

  & input:focus-visible {
    & + span {
      outline-style: auto;
      outline-color: var(--color-main);
      outline-offset: 1px;
    }
  }
}

.c-radio [type="radio"] {
  position: absolute;
  box-sizing: border-box;
  padding: 0;
  opacity: 0;
  pointer-events: none;

  & + span {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 21px;
    padding-left: 21px;
    line-height: 1;
    cursor: pointer;
    user-select: none;
  }

  & + span::before,
  & + span::after {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
    width: 20px;
    height: 20px;
    margin: auto;
    background: var(--color-white);
    border: 2px solid var(--color-main);
    border-radius: 50%;
    transform: translateY(-50%);
    content: "";
  }
  &:not(:checked) + span::after {
    display: none;
  }
  &:checked + span::after {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
    display: block;
    margin: auto;
    background-color: var(--color-main);
    border-radius: 50%;
    transform: translateY(-50%) scale(0.5);
    content: "";
  }

  &.focus-visible:focus + span::before,
  &.focus-visible:focus:not(:checked) + span::after {
    @nest .js-focus-visible & {
      border-color: var(--color-type);
    }
  }

  /* Disabled style */
  &:disabled {
  }

  &:disabled + span:before {
    border-color: var(--color-gray-40);
  }

  &:disabled:not(:checked) + span:before {
    border-color: var(--color-gray-40);
  }

  &:disabled:not(:checked) + span:after {
    border-color: var(--color-gray-40);
  }

  &:disabled:checked + span:before {
  }

  &:disabled:checked + span:after {
    background-color: var(--color-gray-40);
    border-color: var(--color-gray-40);
  }
}

.c-radios {
  & > .c-radio {
    margin-right: 1em;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
.c-radios._vertical {
  display: flex;
  flex-direction: column;
  & > .c-radio {
    width: fit-content;
    margin-right: 0;
  }
}
