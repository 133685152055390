.p-paging__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.p-paging__pages {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.p-paging__page {
  margin: 0 2px;
  @media (--md) {
    margin: 0 8px;
  }
}

.p-paging__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: var(--color-type);
  font-weight: var(--font-bold);
  font-size: var(--text-sm);
  line-height: 1;
  white-space: nowrap;
  text-align: center;
  border: 1px solid var(--color-border);
  transition: background 0.3s, color 0.3s, border 0.3s;
  @media (--md) {
    width: 52px;
    height: 52px;
  }

  &[aria-current="page"] {
    color: var(--color-white);
    background: var(--color-main);
    border: 1px solid var(--color-border);
  }

  &[aria-hidden="true"] {
    visibility: hidden;
  }
}
a.p-paging__link {
  @mixin onHoverFocus {
    color: var(--color-white);
    text-decoration: underline;
    background: var(--color-main);
  }
}

.p-paging__prev,
.p-paging__next {
  & .c-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .p-paging__link {
    font-size: var(--text-lg);
    text-decoration: none;
  }

  & a.p-paging__link {
    @mixin onHoverFocus {
      color: var(--color-white);
      text-decoration: none;
      background: var(--color-main);
    }
  }
}
.p-paging__prev {
  margin-right: 2px;
  @media (--md) {
    margin-right: 8px;
  }
}
.p-paging__next {
  margin-left: 2px;
  @media (--md) {
    margin-left: 8px;
  }
}
