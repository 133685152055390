.l-row__outer {
  display: flow-root;
}

.l-row {
  --l-row-gap: var(--space-2);
  --l-row-gap-x: var(--space-2);
  --l-row-gap-y: var(--space-2);
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--l-row-gap));
  padding: 0;
  list-style: none;

  &[class*="_gap-y"] {
    margin-top: calc(-1 * var(--l-row-gap-y));
    margin-bottom: calc(-1 * var(--l-row-gap-y));

    & > .l-col {
      padding-top: var(--l-row-gap-y);
      padding-bottom: var(--l-row-gap-y);
    }
  }
  &[class*="_gap-x"] {
    margin-right: calc(-1 * var(--l-row-gap-x));
    margin-left: calc(-1 * var(--l-row-gap-x));

    & > .l-col {
      padding-right: var(--l-row-gap-x);
      padding-left: var(--l-row-gap-x);
    }
  }

  @define-mixin row-options $responsive {
    &$(responsive)singleline {
      flex-wrap: nowrap;
    }

    &$(responsive)v-center {
      align-items: center;
    }

    &$(responsive)v-start {
      align-items: flex-start;
    }

    &$(responsive)v-end {
      align-items: flex-end;
    }

    &$(responsive)row-reverse {
      flex-direction: row-reverse;
    }

    &$(responsive)column-reverse {
      flex-direction: column-reverse;
    }

    &$(responsive)equally {
      & > .l-col {
        flex: 1 1 0%;
        float: none;
      }
    }

    &$(responsive)justify-start {
      justify-content: flex-start;
    }
    &$(responsive)justify-end {
      justify-content: flex-end;
    }
    &$(responsive)justify-center {
      justify-content: center;
    }

    &$(responsive)s-between {
      justify-content: space-between;
    }

    &$(responsive)s-around {
      justify-content: space-around;
    }

    &$(responsive)v-border {
      & > .l-col + .l-col {
        border-left: 1px solid var(--color-border);
      }
    }
    &$(responsive)h-border {
      & > .l-col + .l-col {
        border-top: 1px solid var(--color-border);
      }
    }

    &$(responsive)gap-12 {
      --l-row-gap: var(--space-12);
    }
    &$(responsive)gap-x12 {
      --l-row-gap-x: var(--space-12);
    }
    &$(responsive)gap-y12 {
      --l-row-gap-y: var(--space-12);
    }

    &$(responsive)gap-10 {
      --l-row-gap: var(--space-10);
    }
    &$(responsive)gap-x10 {
      --l-row-gap-x: var(--space-10);
    }
    &$(responsive)gap-y10 {
      --l-row-gap-y: var(--space-10);
    }

    &$(responsive)gap-8 {
      --l-row-gap: var(--space-8);
    }
    &$(responsive)gap-x8 {
      --l-row-gap-x: var(--space-8);
    }
    &$(responsive)gap-y8 {
      --l-row-gap-y: var(--space-8);
    }

    &$(responsive)gap-7 {
      --l-row-gap: var(--space-7);
    }
    &$(responsive)gap-x7 {
      --l-row-gap-x: var(--space-7);
    }
    &$(responsive)gap-y7 {
      --l-row-gap-y: var(--space-7);
    }

    &$(responsive)gap-6 {
      --l-row-gap: var(--space-6);
    }
    &$(responsive)gap-x6 {
      --l-row-gap-x: var(--space-6);
    }
    &$(responsive)gap-y6 {
      --l-row-gap-y: var(--space-6);
    }

    &$(responsive)gap-5 {
      --l-row-gap: var(--space-5);
    }
    &$(responsive)gap-x5 {
      --l-row-gap-x: var(--space-5);
    }
    &$(responsive)gap-y5 {
      --l-row-gap-y: var(--space-5);
    }

    &$(responsive)gap-4 {
      --l-row-gap: var(--space-4);
    }
    &$(responsive)gap-x4 {
      --l-row-gap-x: var(--space-4);
    }
    &$(responsive)gap-y4 {
      --l-row-gap-y: var(--space-4);
    }

    &$(responsive)gap-3 {
      --l-row-gap: var(--space-3);
    }
    &$(responsive)gap-x3 {
      --l-row-gap-x: var(--space-3);
    }
    &$(responsive)gap-y3 {
      --l-row-gap-y: var(--space-3);
    }

    &$(responsive)gap-2 {
      --l-row-gap: var(--space-2);
    }
    &$(responsive)gap-x2 {
      --l-row-gap-x: var(--space-2);
    }
    &$(responsive)gap-y2 {
      --l-row-gap-y: var(--space-2);
    }

    &$(responsive)gap-1 {
      --l-row-gap: var(--space-1);
    }
    &$(responsive)gap-x1 {
      --l-row-gap-x: var(--space-1);
    }
    &$(responsive)gap-y1 {
      --l-row-gap-y: var(--space-1);
    }

    &$(responsive)gap-px {
      --l-row-gap: 0.5px;
    }
    &$(responsive)gap-xpx {
      --l-row-gap-x: 0.5px;
    }
    &$(responsive)gap-ypx {
      --l-row-gap-y: 0.5px;
    }

    &$(responsive)gap-0 {
      --l-row-gap: 0;
    }
    &$(responsive)gap-x0 {
      --l-row-gap-x: 0;
    }
    &$(responsive)gap-y0 {
      --l-row-gap-y: 0;
    }
  }

  @mixin row-options ._;
  @media (--to-md) {
    /* prettier-ignore */
    @mixin row-options .s\:_;
  }
  @media (--md) {
    /* prettier-ignore */
    @mixin row-options .m\:_;
  }
  @media (--lg) {
    /* prettier-ignore */
    @mixin row-options .l\:_;
  }
  /*@media (--large) {
    @mixin row-options .xl\:_;
  }*/
}

.l-row {
  & > .l-col {
    box-sizing: border-box;
    margin: 0;
    padding: var(--l-row-gap);

    @define-mixin col-options $responsive {
      &$(responsive)self-center {
        align-self: center;
      }

      &$(responsive)self-start {
        align-self: flex-start;
      }

      &$(responsive)self-end {
        align-self: flex-end;
      }
      &$(responsive)variable {
        flex: 1 1 auto;
      }
      &$(responsive)flex-1 {
        flex: 1 1 0%;
      }

      &$(responsive)col-1 {
        width: 8.3333333333%;
      }

      &$(responsive)col-2 {
        width: 16.6666666667%;
      }

      &$(responsive)col-3 {
        width: 25%;
      }

      &$(responsive)col-4 {
        width: 33.3333333333%;
      }

      &$(responsive)col-5 {
        width: 41.6666666667%;
      }

      &$(responsive)col-6 {
        width: 50%;
      }

      &$(responsive)col-7 {
        width: 58.3333333333%;
      }

      &$(responsive)col-8 {
        width: 66.6666666667%;
      }

      &$(responsive)col-9 {
        width: 75%;
      }

      &$(responsive)col-10 {
        width: 83.3333333333%;
      }

      &$(responsive)col-11 {
        width: 91.6666666667%;
      }

      &$(responsive)col-12 {
        width: 100%;
      }

      &$(responsive)offset-1 {
        margin-left: 8.3333333333%;
      }

      &$(responsive)pull-1 {
        right: 8.3333333333%;
      }

      &$(responsive)push-1 {
        left: 8.3333333333%;
      }

      &$(responsive)offset-2 {
        margin-left: 16.6666666667%;
      }

      &$(responsive)pull-2 {
        right: 16.6666666667%;
      }

      &$(responsive)push-2 {
        left: 16.6666666667%;
      }

      &$(responsive)offset-3 {
        margin-left: 25%;
      }

      &$(responsive)pull-3 {
        right: 25%;
      }

      &$(responsive)push-3 {
        left: 25%;
      }

      &$(responsive)offset-4 {
        margin-left: 33.3333333333%;
      }

      &$(responsive)pull-4 {
        right: 33.3333333333%;
      }

      &$(responsive)push-4 {
        left: 33.3333333333%;
      }

      &$(responsive)offset-5 {
        margin-left: 41.6666666667%;
      }

      &$(responsive)pull-5 {
        right: 41.6666666667%;
      }

      &$(responsive)push-5 {
        left: 41.6666666667%;
      }

      &$(responsive)offset-6 {
        margin-left: 50%;
      }

      &$(responsive)pull-6 {
        right: 50%;
      }

      &$(responsive)push-6 {
        left: 50%;
      }

      &$(responsive)offset-7 {
        margin-left: 58.3333333333%;
      }

      &$(responsive)pull-7 {
        right: 58.3333333333%;
      }

      &$(responsive)push-7 {
        left: 58.3333333333%;
      }

      &$(responsive)offset-8 {
        margin-left: 66.6666666667%;
      }

      &$(responsive)pull-8 {
        right: 66.6666666667%;
      }

      &$(responsive)push-8 {
        left: 66.6666666667%;
      }

      &$(responsive)offset-9 {
        margin-left: 75%;
      }

      &$(responsive)pull-9 {
        right: 75%;
      }

      &$(responsive)push-9 {
        left: 75%;
      }

      &$(responsive)offset-10 {
        margin-left: 83.3333333333%;
      }

      &$(responsive)pull-10 {
        right: 83.3333333333%;
      }

      &$(responsive)push-10 {
        left: 83.3333333333%;
      }

      &$(responsive)offset-11 {
        margin-left: 91.6666666667%;
      }

      &$(responsive)pull-11 {
        right: 91.6666666667%;
      }

      &$(responsive)push-11 {
        left: 91.6666666667%;
      }

      &$(responsive)offset-12 {
        margin-left: 100%;
      }

      &$(responsive)pull-12 {
        right: 100%;
      }

      &$(responsive)push-12 {
        left: 100%;
      }
    }

    @mixin col-options ._;
    @media (--sm) {
      /* prettier-ignore */
      @mixin col-options .s\:_;
    }
    @media (--md) {
      /* prettier-ignore */
      @mixin col-options .m\:_;
    }
    @media (--lg) {
      /* prettier-ignore */
      @mixin col-options .l\:_;
    }

    &[class*="push-"],
    &[class*="pull-"] {
      position: relative;
    }
  }
}
