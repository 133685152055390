.u-text-2xs {
  font-size: var(--text-2xs) !important;
}
.u-text-xs {
  font-size: var(--text-xs) !important;
}
.u-text-sm {
  font-size: var(--text-sm) !important;
}
.u-text-smd {
  font-size: var(--text-smd) !important;
}
.u-text-md {
  font-size: var(--text-md) !important;
}
.u-text-lg {
  font-size: var(--text-lg) !important;
}
.u-text-xl {
  font-size: var(--text-xl) !important;
  line-height: var(--line-height-sm);
}
.u-text-2xl {
  font-size: var(--text-2xl) !important;
  line-height: var(--line-height-sm);
}
.u-text-3xl {
  font-size: var(--text-3xl) !important;
  line-height: var(--line-height-xs);
}
.u-text-3\/5xl {
  font-size: var(--text-3\/5xl) !important;
  line-height: var(--line-height-md);
}
.u-text-4xl {
  font-size: var(--text-4xl) !important;
  line-height: var(--line-height-xs);
}
.u-text-5xl {
  font-size: var(--text-5xl) !important;
  line-height: var(--line-height-xs);
}

.u-line-height-1 {
  line-height: 1 !important;
}
.u-line-height-xs {
  line-height: var(--line-height-xs) !important;
}
.u-line-height-sm {
  line-height: var(--line-height-sm) !important;
}
.u-line-height-md {
  line-height: var(--line-height-md) !important;
}
.u-line-height-lg {
  line-height: var(--line-height-lg) !important;
}
.u-line-height-xl {
  line-height: var(--line-height-xl) !important;
}
.u-line-height-xxl {
  line-height: var(--line-height-xxl) !important;
}

.u-font-sans-serif {
  font-family: var(--font-sans-serif) !important;
}
.u-font-serif {
  font-family: var(--font-serif) !important;
}
.u-font-en {
  font-family: var(--font-en) !important;
}

@define-mixin u-text $responsive {
  $(responsive)-align-left {
    text-align: left !important;
  }
  $(responsive)-align-right {
    text-align: right !important;
  }
  $(responsive)-align-center {
    text-align: center !important;
  }

  $(responsive)-font-w-n {
    font-weight: normal !important;
  }
  $(responsive)-font-w-m {
    font-weight: var(--font-medium) !important;
  }
  $(responsive)-font-w-b {
    font-weight: var(--font-bold) !important;
  }

  $(responsive)-underline {
    text-decoration: underline !important;
  }
  $(responsive)-line-through {
    text-decoration: line-through !important;
  }
  $(responsive)-no-underline {
    text-decoration: none !important;
  }

  $(responsive)-color-main {
    color: var(--color-main) !important;
  }
  $(responsive)-color-main-10 {
    color: var(--color-main-10) !important;
  }
  $(responsive)-color-main-20 {
    color: var(--color-main-20) !important;
  }
  $(responsive)-color-main-30 {
    color: var(--color-main-30) !important;
  }
  $(responsive)-color-main-40 {
    color: var(--color-main-40) !important;
  }
  $(responsive)-color-main-50 {
    color: var(--color-main-50) !important;
  }
  $(responsive)-color-main-60 {
    color: var(--color-main-60) !important;
  }
  $(responsive)-color-main-70 {
    color: var(--color-main-70) !important;
  }
  $(responsive)-color-main-80 {
    color: var(--color-main-80) !important;
  }
  $(responsive)-color-main-90 {
    color: var(--color-main-90) !important;
  }

  $(responsive)-color-sub {
    color: var(--color-sub) !important;
  }
  $(responsive)-color-sub-10 {
    color: var(--color-sub-10) !important;
  }
  $(responsive)-color-sub-20 {
    color: var(--color-sub-20) !important;
  }
  $(responsive)-color-sub-30 {
    color: var(--color-sub-30) !important;
  }
  $(responsive)-color-sub-40 {
    color: var(--color-sub-40) !important;
  }
  $(responsive)-color-sub-50 {
    color: var(--color-sub-50) !important;
  }
  $(responsive)-color-sub-60 {
    color: var(--color-sub-60) !important;
  }
  $(responsive)-color-sub-70 {
    color: var(--color-sub-70) !important;
  }
  $(responsive)-color-sub-80 {
    color: var(--color-sub-80) !important;
  }
  $(responsive)-color-sub-90 {
    color: var(--color-sub-90) !important;
  }

  $(responsive)-color-white {
    color: var(--color-white) !important;
  }
  $(responsive)-color-black {
    color: var(--color-black) !important;
  }

  $(responsive)-color-gray {
    color: var(--color-gray) !important;
  }
  $(responsive)-color-gray-10 {
    color: var(--color-gray-10) !important;
  }
  $(responsive)-color-gray-20 {
    color: var(--color-gray-20) !important;
  }
  $(responsive)-color-gray-30 {
    color: var(--color-gray-30) !important;
  }
  $(responsive)-color-gray-40 {
    color: var(--color-gray-40) !important;
  }
  $(responsive)-color-gray-50 {
    color: var(--color-gray-50) !important;
  }
  $(responsive)-color-gray-60 {
    color: var(--color-gray-60) !important;
  }
  $(responsive)-color-gray-70 {
    color: var(--color-gray-70) !important;
  }
  $(responsive)-color-gray-80 {
    color: var(--color-gray-80) !important;
  }
  $(responsive)-color-gray-90 {
    color: var(--color-gray-90) !important;
  }
}

@mixin u-text .u;
@media (--to-md) {
  /* prettier-ignore */
  @mixin u-text .s\:u;
}
@media (--md) {
  /* prettier-ignore */
  @mixin u-text .m\:u;
}
@media (--lg) {
  /* prettier-ignore */
  @mixin u-text .l\:u;
}
/*@media (--large) {
  @mixin u-text .xl\:u;
}*/
