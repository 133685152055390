@define-mixin u-bg $responsive {
  $(responsive)-bg-main {
    background-color: var(--color-main) !important;
  }
  $(responsive)-bg-main-10 {
    background-color: var(--color-main-10) !important;
  }
  $(responsive)-bg-main-20 {
    background-color: var(--color-main-20) !important;
  }
  $(responsive)-bg-main-30 {
    background-color: var(--color-main-30) !important;
  }
  $(responsive)-bg-main-40 {
    background-color: var(--color-main-40) !important;
  }
  $(responsive)-bg-main-50 {
    background-color: var(--color-main-50) !important;
  }
  $(responsive)-bg-main-60 {
    background-color: var(--color-main-60) !important;
  }
  $(responsive)-bg-main-70 {
    background-color: var(--color-main-70) !important;
  }
  $(responsive)-bg-main-80 {
    background-color: var(--color-main-80) !important;
  }
  $(responsive)-bg-main-90 {
    background-color: var(--color-main-90) !important;
  }

  $(responsive)-bg-sub {
    background-color: var(--color-sub) !important;
  }
  $(responsive)-bg-sub-10 {
    background-color: var(--color-sub-10) !important;
  }
  $(responsive)-bg-sub-20 {
    background-color: var(--color-sub-20) !important;
  }
  $(responsive)-bg-sub-30 {
    background-color: var(--color-sub-30) !important;
  }
  $(responsive)-bg-sub-40 {
    background-color: var(--color-sub-40) !important;
  }
  $(responsive)-bg-sub-50 {
    background-color: var(--color-sub-50) !important;
  }
  $(responsive)-bg-sub-60 {
    background-color: var(--color-sub-60) !important;
  }
  $(responsive)-bg-sub-70 {
    background-color: var(--color-sub-70) !important;
  }
  $(responsive)-bg-sub-80 {
    background-color: var(--color-sub-80) !important;
  }
  $(responsive)-bg-sub-90 {
    background-color: var(--color-sub-90) !important;
  }

  $(responsive)-bg-white {
    background-color: var(--color-white) !important;
  }
  $(responsive)-bg-black {
    background-color: var(--color-black) !important;
  }

  $(responsive)-bg-gray {
    background-color: var(--color-gray) !important;
  }
  $(responsive)-bg-gray-10 {
    background-color: var(--color-gray-10) !important;
  }
  $(responsive)-bg-gray-20 {
    background-color: var(--color-gray-20) !important;
  }
  $(responsive)-bg-gray-30 {
    background-color: var(--color-gray-30) !important;
  }
  $(responsive)-bg-gray-40 {
    background-color: var(--color-gray-40) !important;
  }
  $(responsive)-bg-gray-50 {
    background-color: var(--color-gray-50) !important;
  }
  $(responsive)-bg-gray-60 {
    background-color: var(--color-gray-60) !important;
  }
  $(responsive)-bg-gray-70 {
    background-color: var(--color-gray-70) !important;
  }
  $(responsive)-bg-gray-80 {
    background-color: var(--color-gray-80) !important;
  }
  $(responsive)-bg-gray-90 {
    background-color: var(--color-gray-90) !important;
  }
  $(responsive)-bg-grid {
    background: url(/assets/common/images/grid.png) !important;
  }
  $(responsive)-bg-yw {
    background-color: var(--color-yellow) !important;
  }
}

@mixin u-bg .u;

@media (--to-md) {
  /* prettier-ignore */
  @mixin u-bg .s\:u;
}
@media (--md) {
  /* prettier-ignore */
  @mixin u-bg .m\:u;
}
@media (--lg) {
  /* prettier-ignore */
  @mixin u-bg .l\:u;
}
