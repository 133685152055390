@define-mixin u-sr-only $u {
  $(u)-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border-width: 0;
    clip: rect(0, 0, 0, 0);
  }
  $(u)-not-sr-only {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: visible;
    white-space: normal;
    clip: auto;
  }
}
@mixin u-sr-only .u;
@media (--to-md) {
  /* prettier-ignore */
  @mixin u-sr-only .s\:u;
}
@media (--md) {
  /* prettier-ignore */
  @mixin u-sr-only .m\:u;
}
@media (--lg) {
  /* prettier-ignore */
  @mixin u-sr-only .l\:u;
}
