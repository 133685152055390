.p-breadcrumb {
  margin-bottom: 0;
  color: var(--color-gray-60);

  & a {
    color: var(--color-link);
    text-decoration: none;
    @mixin onHoverFocus {
      text-decoration: underline;
    }
  }
}

.p-breadcrumb__nav {
}

.p-breadcrumb__inner {
  margin: 0;
  margin-left: -4px;
  padding: 8px 0;

  @media (--md) {
    margin-left: -12px;
  }

  & ol {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  & li {
    position: relative;
    margin: 0;
    padding: 0;
    padding-left: 4px;
    font-size: var(--text-xs);

    @media (--md) {
      padding-left: 12px;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    &:before {
      display: inline-block;
      padding: 0 4px 0 0;
      content: "/";
      @media (--md) {
        padding: 0 12px 0 0;
      }
    }
  }
}
