html.is-modal-active:not(.is-modal-scroll-enable) {
  overflow: hidden;
}

.p-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4%;
  overflow: hidden;
  &.is-active {
    display: flex;
  }
}

.p-modal__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(var(--color-gray-90-rgb), 0.86);
  cursor: pointer;
  @nest .is-otherdevice & {
    cursor: default;
  }
}

.p-modal__content {
  position: relative;
  width: 100%;
  max-width: 800px;
  max-height: 100%;
  margin: 0 auto;
}

.p-modal__wrap {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.p-modal__inner {
  padding: 50px 25px 30px;
  background: var(--color-white);
  @media (--md) {
    padding: 50px;
  }
}

.p-modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  padding: 0;
  color: var(--color-type);
  background-color: transparent;
  border: none;
  cursor: pointer;
  appearance: none;
}
