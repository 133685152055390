.c-select {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  & select {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 0.8em;
    padding-right: 2.5em;
    color: var(--color-type);
    line-height: var(--line-height-sm);
    background: var(--color-white);
    border: 1px solid var(--color-border);
    cursor: pointer;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 12px;
    display: block;
    width: 1.25em;
    height: 1.25em;
    vertical-align: middle;
    background-color: var(--color-main);
    transform: translateY(-50%);
    content: "";
    mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"><path d="M480 711 240 471l43-43 197 198 197-197 43 43-240 239Z"/></svg>');
    mask-repeat: no-repeat;
    mask-position: center;
    pointer-events: none;
  }

  &._full {
    width: 100%;
  }
}

.c-select select:disabled {
  background: var(--color-gray-20);
  cursor: auto;
}

.c-select._disabled::after {
  background-color: var(--color-gray-50);
}
