.p-card01 {
  position: relative;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-areas:
    "media"
    "header"
    "body";
  grid-template-columns: 1fr;

  & .p-card__media {
    grid-area: media;
  }
  & .p-card__header {
    grid-area: header;
  }
  & .p-card__body {
    grid-area: body;
  }

  & > * + * {
    margin-top: var(--space-2);
  }

  & :where(p) {
    margin: 0;
  }
}

a.p-card01 {
  color: var(--color-type);
  text-decoration: none;
}
