.c-card-heading {
  margin: 0;
  font-weight: var(--font-bold);
  font-size: var(--text-sm);
  line-height: var(--line-height-sm);
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: inherit;
    line-height: inherit;
  }

  &._lg {
    font-size: var(--text-lg);
  }
  &._xl {
    font-size: var(--text-xl);
  }
}

a .c-card-heading {
  text-decoration: underline;
}
a {
  @mixin onHoverFocus {
    & .c-card-heading {
      text-decoration: none;
    }
  }
}
