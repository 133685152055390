.c-text-input {
  width: 100%;
  height: 40px;
  padding: 0 0.8em;
  color: var(--color-type);
  line-height: var(--line-height-sm);
  background: var(--color-white);
  border: 1px solid var(--color-border);
  transition: border 0.2s;
  -webkit-appearance: none;
}

.c-text-input:disabled {
  color: var(--color-gray-60);
  background: var(--color-gray-20);
}

.c-text-input._error {
  background: var(--color-sub-20);
  border-color: var(--color-sub);
}
