[class^="l-column-stack"],
[class*=" l-column-stack"] {
  display: flex !important;
  flex-wrap: wrap !important;
  margin-top: calc(-1 * var(--column-stack-gap)) !important;
  margin-left: calc(-1 * var(--column-stack-gap)) !important;
  & > * {
    margin-top: var(--column-stack-gap) !important;
    margin-left: var(--column-stack-gap) !important;
  }
}

@media (--to-md) {
  [class^="s:l-column-stack"],
  [class*=" s:l-column-stack"] {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-top: calc(-1 * var(--column-stack-gap)) !important;
    margin-left: calc(-1 * var(--column-stack-gap)) !important;
    & > * {
      margin-top: var(--column-stack-gap) !important;
      margin-left: var(--column-stack-gap) !important;
    }
  }
}
@media (--md) {
  [class^="m:l-column-stack"],
  [class*=" m:l-column-stack"] {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-top: calc(-1 * var(--column-stack-gap)) !important;
    margin-left: calc(-1 * var(--column-stack-gap)) !important;
    & > * {
      margin-top: var(--column-stack-gap) !important;
      margin-left: var(--column-stack-gap) !important;
    }
  }
}
@media (--lg) {
  [class^="l:l-column-stack"],
  [class*=" l:l-column-stack"] {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-top: calc(-1 * var(--column-stack-gap)) !important;
    margin-left: calc(-1 * var(--column-stack-gap)) !important;
    & > * {
      margin-top: var(--column-stack-gap) !important;
      margin-left: var(--column-stack-gap) !important;
    }
  }
}

@define-mixin l-column-stack $responsive {
  $(responsive)-column-stack {
    --column-stack-gap: var(--space-4);
  }
  $(responsive)-column-stack-0 {
    --column-stack-gap: 0;
  }
  $(responsive)-column-stack-px {
    --column-stack-gap: 1px;
  }
  $(responsive)-column-stack-1 {
    --column-stack-gap: var(--space-1);
  }
  $(responsive)-column-stack-2 {
    --column-stack-gap: var(--space-2);
  }
  $(responsive)-column-stack-3 {
    --column-stack-gap: var(--space-3);
  }
  $(responsive)-column-stack-4 {
    --column-stack-gap: var(--space-4);
  }
  $(responsive)-column-stack-6 {
    --column-stack-gap: var(--space-6);
  }
  $(responsive)-column-stack-8 {
    --column-stack-gap: var(--space-8);
  }
  $(responsive)-column-stack-10 {
    --column-stack-gap: var(--space-10);
  }
  $(responsive)-column-stack-12 {
    --column-stack-gap: var(--space-12);
  }
  $(responsive)-column-stack-16 {
    --column-stack-gap: var(--space-16);
  }
  $(responsive)-column-stack-24 {
    --column-stack-gap: var(--space-24);
  }
}

@mixin l-column-stack .l;
@media (--to-md) {
  /* prettier-ignore */
  @mixin l-column-stack .s\:l;
}
@media (--md) {
  /* prettier-ignore */
  @mixin l-column-stack .m\:l;
}
@media (--lg) {
  /* prettier-ignore */
  @mixin l-column-stack .l\:l;
}
