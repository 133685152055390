.c-button-main {
  --padding-y: var(--space-4);
  --padding-x: var(--space-4);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-y) var(--padding-x);
  overflow: visible;
  color: var(--color-type);
  font-weight: var(--font-normal);
  line-height: var(--line-height-xs);
  text-align: left;
  text-decoration: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  cursor: pointer;
  transition: background 0.3s, color 0.3s, border 0.3s;
  appearance: none;

  @mixin onHoverFocus {
    color: var(--color-white);
    text-decoration: none;
    background: var(--color-main);
    border: 1px solid var(--color-main);
  }

  & > * + * {
    margin-left: var(--space-2);
  }
}

.c-button-main__text {
  flex: 1;
  font-weight: bold;
}

.c-button-main._arrow-anim {
  @mixin onHoverFocus {
    & .c-button-main__icon {
      animation-name: icon-arrow;
      animation-duration: 0.4s;
      animation-timing-function: var(--ease);
    }
  }
}

.c-button-main._arrow-anim-back {
  @mixin onHoverFocus {
    & .c-button-main__icon {
      animation-name: icon-arrow-back;
      animation-duration: 0.4s;
      animation-timing-function: var(--ease);
    }
  }
}

.c-button-main._full {
  width: 100%;
}
.c-button-main._sm-full {
  @media (--to-md) {
    width: 100%;
  }
}

.c-button-main:disabled,
.c-button-main._disabled {
  color: var(--color-gray-40) !important;
  background: var(--color-gray-10) !important;
  pointer-events: none;
}

.c-button-main._color-main {
  color: var(--color-white);
  background-color: var(--color-main);
  border: 1px solid transparent;
  @mixin onHoverFocus {
    color: var(--color-white);
    background-color: var(--color-main-40);
    border: 1px solid transparent;
  }
}

.c-button-main._color-sub {
  color: var(--color-white);
  background-color: var(--color-sub);
  border: 1px solid transparent;
  @mixin onHoverFocus {
    color: var(--color-white);
    background-color: var(--color-sub-40);
    border: 1px solid transparent;
  }
}

.c-button-main._color-contact {
  padding: var(--space-2\.5) var(--space-5);
  color: #fff;
  background: var(--color-type);
  border-radius: var(--space-1);
}

.c-button-main._detail {
  display: flex;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding: var(--space-2\.5) var(--space-5);
  text-align: center;
  border-radius: var(--space-1);
  @mixin onHoverFocus {
    color: var(--color-type);
    background-color: var(--color-yellow);
    border: 1px solid transparent;
  }
  &._contact {
    height: 64px;
    color: var(--color-white);
    background-color: var(--color-type);
    @mixin onHoverFocus {
      color: var(--color-white);
      background-color: var(--color-gray-70);
      border: 1px solid transparent;
    }
  }
}
