html.is-gnav-open {
  @media (--to-lg) {
    overflow: hidden;
  }
}

html.is-gnav-hover-open {
  @media (--lg) {
    & .p-header__inner {
      &:before {
        z-index: 1;
        background: rgba(var(--color-black-rgb), 0.7);
      }
      & .p-header__global-link[aria-expanded="false"]:before {
        opacity: 0.4;
      }
    }
  }
}

.p-header a {
  text-decoration: none;
}

.p-header__inner {
  position: relative;
  z-index: 1001;
  background: var(--color-white);

  @media (--lg) {
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(var(--color-black-rgb), 0);
      transition: 0.15s;
      content: "";
      pointer-events: none;
    }
  }
}

.p-header__information {
  padding: var(--space-4) 0 !important;
  font-weight: bold;
  font-size: var(--14px);
  text-align: center;
  background: var(--color-yellow);
  @media (--to-md) {
    font-size: var(--12px);
  }
}

.p-header__row {
  @media (--lg) {
    display: flex;
    align-items: center;
  }
  @media (--to-md) {
    padding: 0;
  }
}
.p-header__col {
  &._top {
    display: flex;
    align-items: center;
    height: 124px;
    @media (--to-lg) {
      height: 72px;
      padding: 0;
    }
  }
  &._bottom {
    flex: 1;

    @media (--to-lg) {
      position: fixed;
      top: 72px;
      left: 0;
      z-index: 1;
      display: none;
      width: 100%;
      height: calc(100% - 48px);
      overflow: auto;
      background: var(--color-white);

      &[aria-hidden="false"] {
        display: block;
      }
    }
  }
}

.p-header__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  width: 100%;
  height: 100%;
  background: transparent;

  @media (--lg) {
    background: rgba(var(--color-black-rgb), 0.7);
  }
}
