.p-news {
}
.p-news__items {
  margin: 0;
  padding: 0;
  list-style: none;

  & .p-news__item {
    &:first-child {
      padding-top: 0;
    }
  }
}
.p-news__item {
  padding: var(--space-4) 0;
  border-bottom: 1px solid var(--color-border-light);
}
.p-news__link {
  display: block;
  text-decoration: none;
  @mixin onHoverFocus {
    & .p-news__text-item {
      text-decoration: none;
    }
  }
}
.p-news__info {
  display: flex;
  align-items: center;
  line-height: var(--line-height-xs);
}
.p-news__info-date {
  font-weight: var(--font-bold);
}
.p-news__info-tag {
  width: 126px;
  margin-left: var(--space-2);
  padding: var(--space-1);
  color: var(--color-gray-70);
  font-size: var(--10px);
  text-align: center;
  border: 1px solid var(--color-gray-40);
  border-radius: 14px;
  @media (--md) {
    width: 168px;
    margin-left: var(--space-6);
    font-size: var(--12px);
  }
}
.p-news__text {
  display: flex;
  align-items: center;
  min-width: 0;
  margin-top: var(--space-2);
}
.p-news__text-item {
  margin: 0;
  text-decoration: underline;
}
.p-news__text-icon {
  display: flex;
  margin-left: var(--space-2);
  font-size: var(--20px);
  @media (--md) {
    font-size: var(--24px);
  }
}
