@keyframes icon-arrow {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  49.9% {
    transform: translateX(50%);
    opacity: 0;
  }
  50% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes icon-arrow-back {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  49.9% {
    transform: translateX(-50%);
    opacity: 0;
  }
  50% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes icon-arrow-down {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  49.9% {
    transform: translateY(50%);
    opacity: 0;
  }
  50% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
