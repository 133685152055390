.c-button-second {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  color: var(--color-type);
  font-weight: var(--font-normal);
  font-size: var(--text-lg);
  line-height: var(--line-height-xs);
  text-align: left;
  text-decoration: none;
  border: none;
  cursor: pointer;
  appearance: none;

  @mixin onHoverFocus {
    & .c-button-second__text {
      text-decoration: underline;
    }
    & .c-button-second__icon {
      color: var(--color-white);
      background-color: var(--color-main);
    }
  }

  & > * + * {
    margin-left: var(--space-2);
  }
}

.c-button-second__text {
  flex: 1;
}

.c-button-second__icon {
  display: grid;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  color: var(--color-type);
  background-color: var(--color-gray-30);
  border-radius: 9999px;
  transition: color 0.3s, background-color 0.3s;
  place-items: center;

  & > svg {
    width: 0.8em;
  }
}

.c-button-second._arrow-anim {
  @mixin onHoverFocus {
    & .c-button-second__icon > * {
      animation-name: icon-arrow;
      animation-duration: 0.4s;
      animation-timing-function: var(--ease);
    }
  }
}

.c-button-second._arrow-anim-back {
  @mixin onHoverFocus {
    & .c-button-second__icon > * {
      animation-name: icon-arrow-back;
      animation-duration: 0.4s;
      animation-timing-function: var(--ease);
    }
  }
}

.c-button-second._color-main {
  & .c-button-second__icon {
    color: var(--color-white);
    background-color: var(--color-main);
    border-radius: 9999px;
  }

  @mixin onHoverFocus {
    & .c-button-second__icon {
      color: var(--color-type);
      background-color: var(--color-gray-30);
      border-radius: 9999px;
    }
  }
}
