.c-heading {
  text-align: left;

  &._lv1 {
    position: relative;
    margin: 0.8em 0;
    padding-bottom: 12px;
    color: var(--color-main-70);
    font-weight: var(--font-bold);
    font-size: var(--text-4xl);
    line-height: 1.33;

    &:after {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background: var(--color-gray-30);
      content: "";
    }
  }

  &._lv2 {
    position: relative;
    padding-bottom: 12px;
    color: var(--color-type);
    font-weight: var(--font-bold);
    font-size: var(--text-3xl);
    line-height: var(--line-height-xs);

    &:after {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      display: block;
      width: 30px;
      height: 2px;
      background: var(--color-main-70);
      content: "";
      @media (--md) {
        width: 60px;
      }
    }
  }

  &._lv3 {
    padding-left: 1rem;
    font-weight: var(--font-bold);
    font-size: var(--text-lv3);
    line-height: var(--line-height-sm);
    border-left: 6px solid #222;
  }

  &._lv4 {
    font-weight: var(--font-bold);
    font-size: var(--text-lg);
    line-height: var(--line-height-md);
  }

  &._lv5 {
    font-weight: var(--font-bold);
    font-size: var(--text-md);
    line-height: var(--line-height-md);
  }

  &._lv6 {
    font-weight: var(--font-normal);
    font-size: var(--text-md);
    line-height: var(--line-height-md);
  }

  &._lv1,
  &._lv2,
  &._lv3,
  &._lv4,
  &._lv5,
  &._lv6 {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
