:root {
  /* アニメーション関連 */
  --ease: cubic-bezier(0.215, 0.61, 0.355, 1);
  --opacity: 0.75;
  --scale: 1.03;

  /* u-content-full用 */
  --contentfull-margin: calc(
    ((100vw - 100% - var(--scrollbar-width, 0px)) / 2) * -1
  );
  --contentfull-padding: calc((100vw - 100% - var(--scrollbar-width, 0px)) / 2);
}
