.c-image {
  &._full {
    width: 100%;
    max-width: none;
  }
  &._90 {
    max-width: 90%;
  }
  &._company {
    padding: 12rem;
    background: url(/assets/common/images/company.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    @media (--md-lg) {
      padding: 9rem;
    }
    @media (--sm-md) {
      padding: 12rem;
    }
    @media (--to-sm) {
      padding: 7rem;
    }
  }
  &._product {
    padding: 9rem;
    @media (--to-lg) {
      padding: 7rem;
    }
    @media (--to-sm) {
      padding: 4rem;
    }
    &._01 {
      background: url(/assets/common/images/products/img_01.jpg);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      @media (--to-lg) {
        background-position: 0%;
      }
    }
    &._02 {
      background: url(/assets/common/images/products/img_02.jpg);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      @media (--to-lg) {
        background-position: 0%;
      }
    }
    &._03 {
      background: url(/assets/common/images/products/img_03.jpg);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      @media (--to-lg) {
        background-position: 0%;
      }
    }
  }
}
