.p-accordion {
  margin: 16px 0;
  border-bottom: 1px solid var(--color-border);

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  &.is-open {
  }
}

.p-accordion__inner {
  margin-top: 0;
  margin-bottom: 0;
}

.p-accordion__title {
  margin-top: 0;
  margin-bottom: 0;
}

.p-accordion__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 16px 0;
  color: var(--color-type);
  font-weight: var(--font-bold);
  line-height: var(--line-height-md);
  text-align: left;
  border: none;
  border-top: 1px solid var(--color-border);
  border-radius: 0;
  cursor: pointer;
  transition: none;
  appearance: none;

  &[aria-expanded="true"] {
    & .p-accordion__icon {
      transform: rotate(-180deg);
    }
  }
}

.p-accordion__icon {
  color: var(--color-main);
}

.p-accordion__contents {
  display: none;
  margin: 0;
  padding: 0 0 16px 0;

  @media (--md) {
    padding: 0 0 32px 0;
  }

  &[aria-hidden="false"] {
    display: block;
  }
  &[aria-hidden="true"] {
    display: none;
  }

  @nest html.no-js & {
    display: block;
  }
}
