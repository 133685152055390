@define-mixin u-overflow $u {
  $(u)-overflow-x-auto {
    overflow-x: auto;
  }
  $(u)-overflow-y-auto {
    overflow-y: auto;
  }
  $(u)-overflow-auto {
    overflow: auto;
  }
  $(u)-overflow-x-hidden {
    overflow-x: hidden;
  }
  $(u)-overflow-y-hidden {
    overflow-y: hidden;
  }
  $(u)-overflow-hidden {
    overflow: hidden;
  }
}
@mixin u-overflow .u;
@media (--to-md) {
  /* prettier-ignore */
  @mixin u-overflow .s\:u;
}
@media (--md) {
  /* prettier-ignore */
  @mixin u-overflow .m\:u;
}
@media (--lg) {
  /* prettier-ignore */
  @mixin u-overflow .l\:u;
}
