@define-mixin u-display $responsive {
  $(responsive)-inline {
    display: inline !important;
  }
  $(responsive)-inline-block {
    display: inline-block !important;
  }
  $(responsive)-block {
    display: block !important;
  }
  $(responsive)-flex {
    display: flex !important;
  }
  $(responsive)-flex-row {
    flex-direction: row !important;
  }
  $(responsive)-flex-col {
    flex-direction: column !important;
  }
  $(responsive)-flex-col-rev {
    flex-direction: column-reverse !important;
  }
}

@mixin u-display .u;
@media (--to-md) {
  /* prettier-ignore */
  @mixin u-display .s\:u;
}
@media (--md) {
  /* prettier-ignore */
  @mixin u-display .m\:u;
}
@media (--lg) {
  /* prettier-ignore */
  @mixin u-display .l\:u;
}
/*@media (--large) {
  @mixin u-display .xl\:u;
}*/
