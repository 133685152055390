.p-footer {
  width: 100%;
  overflow: hidden;
}

.p-footer__contents {
  padding: 24px 0 16px;
  color: var(--color-white);
  font-weight: bold;
  font-size: var(--12px);
  text-align: center;
  background: var(--color-type);
}
