.p-header-language-switcher {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.p-header-language-switcher__item {
  position: relative;

  & + .p-header-language-switcher__item {
    margin-left: 8px;
    padding-left: 16px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      line-height: 1;
      content: "/";
    }
  }
}
.p-header-language-switcher__link {
  display: block;
  padding: 4px 12px;
  color: var(--color-type);
  font-size: var(--13px);
  line-height: 1;
  text-align: center;
  background: transparent;
  border-radius: 100px;

  &.is-active {
    color: var(--color-white);
    background: var(--color-main-70);
  }
}
