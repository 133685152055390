input[type="checkbox"] {
  accent-color: var(--color-main);
}

.c-checkbox {
  display: inline-block;
  margin: 0;
  font-weight: normal;
  line-height: var(--line-height-sm);
  border-radius: 0;
  box-shadow: none;

  & .label-text {
    display: inline-block;
    padding-left: 0.5em;
    color: var(--color-type);
    line-height: var(--line-height-sm);
    vertical-align: middle;
  }

  & input:focus-visible {
    & + span {
      outline-style: auto;
      outline-color: var(--color-main);
      outline-offset: 1px;
    }
  }
}

.c-checkbox [type="checkbox"] {
  box-sizing: border-box;
  padding: 0;

  & + span {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 25px;
    padding-left: 25px;
    color: var(--color-type);
    line-height: 1;
    cursor: pointer;
    user-select: none;
  }

  & + span:before,
  & + span:after {
    position: absolute;
    left: 0;
    z-index: 1;
    font-size: var(--24px);
    content: "";
  }

  /* Unchecked style */
  &:not(:checked) {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    & + span:before {
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      margin: auto;
      border: 3px solid transparent;
      transform: rotateZ(37deg);
      transform-origin: 100% 100%;
    }

    & + span:after {
      top: 0px;
      z-index: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      background-color: var(--color-white);
      border: 1px solid var(--color-border);
    }
  }

  /* Checked style */
  &:checked {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    & + span:before {
      top: 0.12em;
      left: 0.05em;
      width: 0.35em;
      height: 0.6em;
      border-top: transparent;
      border-right: 2px solid var(--color-white);
      border-bottom: 2px solid var(--color-white);
      border-left: transparent;
      transform: rotateZ(45deg);
      transform-origin: 100% 100%;
    }

    & + span:after {
      top: 0;
      z-index: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      background-color: var(--color-main);
      border: 1px solid var(--color-main);
    }
  }

  /* Focused styles */
  &.focus-visible:focus + span:after {
    @nest .js-focus-visible & {
      border-color: var(--color-type);
    }
  }

  /* Disabled style */
  &:disabled {
  }

  &:disabled + span:before {
  }

  &:disabled:not(:checked) + span:before {
    background-color: transparent;
    border: 1px solid transparent;
  }

  &:disabled:not(:checked) + span:after {
    background-color: var(--color-gray-20);
    border-color: var(--color-gray-50);
  }

  &:disabled:checked + span:before {
    background-color: transparent;
    border-color: var(--color-gray-70);
  }

  &:disabled:checked + span:after {
    color: var(--color-type);
    background-color: var(--color-gray-20);
    border-color: var(--color-gray-50);
  }
}

.c-checkboxes {
  & > .c-checkbox {
    margin-right: 1em;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
.c-checkboxes._vertical {
  display: flex;
  flex-direction: column;
  & > .c-checkbox {
    width: fit-content;
    margin-right: 0;
  }
}
