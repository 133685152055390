.p-page-anchors {
}

.p-page-anchors__items {
  display: flex;
  flex-wrap: wrap;
  margin: -1px;
  padding: 0;
  list-style: none;
}

.p-page-anchors__item {
  display: flex;
  width: 50%;
  margin: 0;
  padding: 1px;

  @media (--md) {
    width: 25%;
  }
}

.p-page-anchors__link {
  position: relative;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 8px;
  padding-right: 30px;
  font-weight: var(--font-bold);
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  background: var(--color-gray-10);

  &::after,
  &::before {
    text-decoration: underline;
  }

  @mixin onHoverFocus {
    & .p-page-anchors__icon {
      animation-name: icon-arrow-down;
      animation-duration: 0.4s;
      animation-timing-function: var(--ease);
    }
  }
}
