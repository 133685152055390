.p-header-utility {
  display: none;

  @media (--lg) {
    display: block;
    padding: 12px 0;
  }
}

.p-header-utility__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 0 4px;

  @media (--lg) {
    padding: 0;
  }
}

.p-header-utility__contact {
  @media (--lg) {
    margin-left: 32px;
  }
}

.p-header-utility__button {
  @media (--to-lg) {
    display: inline-flex;
    align-items: center;
    padding: 16px 32px;
    border: none;
    border-radius: 100px;

    & .icon {
      margin-left: 4px;
    }
  }

  @media (--lg) {
    color: var(--color-type);
    font-weight: var(--font-normal);
    font-size: var(--13px);
    line-height: 1;
    text-align: left;
    border-radius: 0;

    & .icon {
      margin-right: 4px;
    }
  }
}
