:root {
  /* 固定font-size */
  --10px: 0.625rem;
  --11px: 0.6875rem;
  --12px: 0.75rem;
  --13px: 0.8125rem;
  --14px: 0.875rem;
  --15px: 0.9375rem;
  --16px: 1rem;
  --17px: 1.0625rem;
  --18px: 1.125rem;
  --19px: 1.1875rem;
  --20px: 1.25rem;
  --22px: 1.375rem;
  --24px: 1.5rem;
  --26px: 1.625rem;
  --28px: 1.75rem;
  --30px: 1.875rem;
  --32px: 2rem;
  --36px: 2.25rem;
  --40px: 2.5rem;
  --48px: 3rem;
  --50px: 3.125rem;
  --60px: 3.75rem;

  /* レスポンシブfont-size
    参考:
    https://min-max-calculator.9elements.com/
    https://coliss.com/articles/build-websites/operation/css/css-clamp-calculator.html
  */
  --text-2xs: clamp(0.625rem, 0.568rem + 0.24vw, 0.75rem);
  --text-xs: clamp(0.75rem, 0.693rem + 0.24vw, 0.875rem);
  --text-sm: clamp(0.813rem, 0.756rem + 0.24vw, 0.938rem);
  --text-smd: clamp(1rem, 0.955rem + 0.23vw, 1.125rem);
  --text-md: clamp(0.875rem, 0.852rem + 0.11vw, 0.938rem);
  --text-lg: clamp(1rem, 0.943rem + 0.24vw, 1.125rem);
  --text-xl: clamp(1.125rem, 0.955rem + 0.73vw, 1.5rem);
  --text-2xl: clamp(1.5rem, 1.386rem + 0.48vw, 1.75rem);
  --text-3xl: clamp(1.75rem, 1.636rem + 0.48vw, 2rem);
  --text-3\/5xl: clamp(1.25rem, 0.886rem + 1.82vw, 2.25rem);
  --text-4xl: clamp(2rem, 1.818rem + 0.91vw, 2.5rem);
  --text-5xl: clamp(2.5rem, 1.648rem + 3.64vw, 4.375rem);

  --text-lv3: clamp(1.125rem, 1.08rem + 0.23vw, 1.25rem);

  /* font-family */
  --font-sans-serif: "Noto Sans JP", sans-serif;
  --font-serif: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro",
    "Hiragino Mincho Pro", serif;
  --font-en: "Roboto", sans-serif;

  /* font-weight */
  --font-normal: 400;
  --font-medium: 500;
  --font-bold: 700;

  /* line-height */
  --line-height-xs: 1.4;
  --line-height-sm: 1.5;
  --line-height-md: 1.6;
  --line-height-lg: 1.8;
  --line-height-xl: 2.2;
  --line-height-xxl: 2.4;
}
