.p-card02 {
  position: relative;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-areas:
    "media   header"
    "media   body  ";
  grid-template-columns: 1fr 2fr;

  & .p-card__media {
    grid-area: media;
  }
  & .p-card__header {
    grid-area: header;
  }
  & .p-card__body {
    grid-area: body;
  }

  @media (--to-md) {
    & .p-card__header {
      margin-top: 0;
      margin-left: var(--space-2);
    }
    & .p-card__body,
    & .p-card__footer {
      margin-left: var(--space-2);
    }
  }

  @media (--md) {
    grid-template-areas:
      "media"
      "header"
      "body"
      "footer";
    grid-template-columns: 1fr;
  }

  & > * + * {
    margin-top: var(--space-2);
  }

  & :where(p) {
    margin: 0;
  }
}

a.p-card02 {
  color: var(--color-type);
  text-decoration: none;
}
