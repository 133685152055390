.c-dl {
  margin: 16px 0;
  border-bottom: 1px solid var(--color-border);

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid var(--color-border);
    @media (--md) {
      flex-wrap: nowrap;
    }
  }
  & > div > dt {
    width: 100%;
    margin: 0;
    padding: 16px 0;
    font-weight: var(--font-bold);
    @media (--md) {
      width: 16%;
    }
  }
  & > div > dd {
    width: 100%;
    margin: 0;
    padding: 0 0 16px;
    @media (--md) {
      flex: 1;
      padding: 16px 0;
      padding-left: 40px;
    }
  }
}
