*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}

html {
  overflow-x: hidden;
  font-size: 100%;
  text-rendering: optimizeLegibility;
  text-underline-offset: 0.07em;
  line-break: normal;
  hanging-punctuation: allow-end;
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizeSpeed;
}

body,
button,
input,
select,
textarea {
  font-family: var(--font-sans-serif);
}

button:disabled {
  color: inherit;
}

body {
  color: var(--color-type);
  font-weight: var(--font-normal);
  font-size: var(--text-md);
  font-family: var(--font-sans-serif);
  line-height: var(--line-height-md);
}

p {
  margin: var(--space-2) 0;
  line-height: var(--line-height-lg);
  &:where(:first-child) {
    margin-top: 0;
  }
  &:where(:last-child) {
    margin-bottom: 0;
  }
}

h1 {
  margin: 0.8em 0;
  font-weight: var(--font-bold);
  font-size: var(--text-4xl);
  line-height: 1.33;
}

h2 {
  margin: 0.8em 0;
  font-weight: var(--font-bold);
  font-size: var(--text-3xl);
  line-height: var(--line-height-xs);
}

h3 {
  margin: 0.6em 0;
  font-weight: var(--font-bold);
  font-size: var(--text-2xl);
  line-height: var(--line-height-sm);
}

h4 {
  margin: 0.4em 0;
  font-weight: var(--font-bold);
  font-size: var(--text-xl);
  line-height: var(--line-height-md);
}

h5,
h6 {
  margin: 0.3em 0;
  font-weight: var(--font-normal);
  font-size: var(--text-lg);
  line-height: var(--line-height-md);
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}
:where(a) {
  color: var(--color-link);
  text-decoration: underline;

  @mixin onHoverFocus {
    text-decoration: none;
  }
}

:focus-visible {
  outline-style: auto;
  outline-color: var(--color-main);
  outline-offset: 1px;
}

button {
  padding: 0;
  font-size: 1em;
  background: transparent;
}

img,
picture {
  display: block;
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

blockquote {
  margin: revert;
  padding-left: 24px;
  border-left: 3px solid #000;
}

:where(b, strong, h1, h2, h3, h4, h5, h6, th, optgroup) {
  font-weight: var(--font-bold);
}
