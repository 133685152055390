.l-container {
  width: 94vw;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;

  @media (--md) {
    width: calc(100% - 40px);
  }

  &._middle {
    max-width: 1200px;
  }
  &._narrow {
    max-width: 960px;
  }
}

.l-section,
section.l-wrapper {
  margin-top: 60px;
  margin-bottom: 16px;

  @media (--md) {
    margin-top: 100px;
    margin-bottom: 16px;
  }

  &:where(:first-child) {
    margin-top: 0;

    @media (--md) {
      margin-top: 0;
    }
  }
  &:where(:last-child) {
    margin-bottom: 0;
    @media (--md) {
      margin-bottom: 0;
    }
  }

  &._narrow {
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
  }

  &._full {
    margin: 0 calc(50% - 50vw);
    padding-top: 60px;
    padding-bottom: 16px;
    @media (--md) {
      padding-top: 100px;
      padding-bottom: 16px;
    }
  }
}
