@define-mixin u-spacer $u {
  $(u)-m-auto {
    margin: auto !important;
  }
  $(u)-m-0 {
    margin: 0 !important;
  }
  $(u)-m-px {
    margin: var(--space-px) !important;
  }
  $(u)-m-1 {
    margin: var(--space-1) !important;
  }
  $(u)-m-2 {
    margin: var(--space-2) !important;
  }
  $(u)-m-3 {
    margin: var(--space-3) !important;
  }
  $(u)-m-4 {
    margin: var(--space-4) !important;
  }
  $(u)-m-5 {
    margin: var(--space-5) !important;
  }
  $(u)-m-6 {
    margin: var(--space-6) !important;
  }
  $(u)-m-7 {
    margin: var(--space-7) !important;
  }
  $(u)-m-8 {
    margin: var(--space-8) !important;
  }
  $(u)-m-9 {
    margin: var(--space-9) !important;
  }
  $(u)-m-10 {
    margin: var(--space-10) !important;
  }
  $(u)-m-12 {
    margin: var(--space-12) !important;
  }
  $(u)-m-16 {
    margin: var(--space-16) !important;
  }
  $(u)-m-24 {
    margin: var(--space-24) !important;
  }
  $(u)-m-32 {
    margin: var(--space-32) !important;
  }
  $(u)-m-40 {
    margin: var(--space-40) !important;
  }
  $(u)-m-48 {
    margin: var(--space-48) !important;
  }
  $(u)-m-56 {
    margin: var(--space-56) !important;
  }
  $(u)-m-64 {
    margin: var(--space-64) !important;
  }
  $(u)-mt-auto {
    margin-top: auto !important;
  }
  $(u)-mt-0 {
    margin-top: 0 !important;
  }
  $(u)-mt-px {
    margin-top: var(--space-px) !important;
  }
  $(u)-mt-1 {
    margin-top: var(--space-1) !important;
  }
  $(u)-mt-2 {
    margin-top: var(--space-2) !important;
  }
  $(u)-mt-3 {
    margin-top: var(--space-3) !important;
  }
  $(u)-mt-4 {
    margin-top: var(--space-4) !important;
  }
  $(u)-mt-5 {
    margin-top: var(--space-5) !important;
  }
  $(u)-mt-6 {
    margin-top: var(--space-6) !important;
  }
  $(u)-mt-7 {
    margin-top: var(--space-7) !important;
  }
  $(u)-mt-8 {
    margin-top: var(--space-8) !important;
  }
  $(u)-mt-9 {
    margin-top: var(--space-9) !important;
  }
  $(u)-mt-10 {
    margin-top: var(--space-10) !important;
  }
  $(u)-mt-12 {
    margin-top: var(--space-12) !important;
  }
  $(u)-mt-14 {
    margin-top: var(--space-14) !important;
  }
  $(u)-mt-16 {
    margin-top: var(--space-16) !important;
  }
  $(u)-mt-20 {
    margin-top: var(--space-20) !important;
  }
  $(u)-mt-24 {
    margin-top: var(--space-24) !important;
  }
  $(u)-mt-29 {
    margin-top: var(--space-29) !important;
  }
  $(u)-mt-32 {
    margin-top: var(--space-32) !important;
  }
  $(u)-mt-40 {
    margin-top: var(--space-40) !important;
  }
  $(u)-mt-48 {
    margin-top: var(--space-48) !important;
  }
  $(u)-mt-56 {
    margin-top: var(--space-56) !important;
  }
  $(u)-mt-64 {
    margin-top: var(--space-64) !important;
  }
  $(u)-mr-auto {
    margin-right: auto !important;
  }
  $(u)-mr-0 {
    margin-right: 0 !important;
  }
  $(u)-mr-px {
    margin-right: var(--space-px) !important;
  }
  $(u)-mr-1 {
    margin-right: var(--space-1) !important;
  }
  $(u)-mr-2 {
    margin-right: var(--space-2) !important;
  }
  $(u)-mr-3 {
    margin-right: var(--space-3) !important;
  }
  $(u)-mr-4 {
    margin-right: var(--space-4) !important;
  }
  $(u)-mr-5 {
    margin-right: var(--space-5) !important;
  }
  $(u)-mr-6 {
    margin-right: var(--space-6) !important;
  }
  $(u)-mr-7 {
    margin-right: var(--space-7) !important;
  }
  $(u)-mr-8 {
    margin-right: var(--space-8) !important;
  }
  $(u)-mr-9 {
    margin-right: var(--space-9) !important;
  }
  $(u)-mr-10 {
    margin-right: var(--space-10) !important;
  }
  $(u)-mr-12 {
    margin-right: var(--space-12) !important;
  }
  $(u)-mr-16 {
    margin-right: var(--space-16) !important;
  }
  $(u)-mr-24 {
    margin-right: var(--space-24) !important;
  }
  $(u)-mr-32 {
    margin-right: var(--space-32) !important;
  }
  $(u)-mr-40 {
    margin-right: var(--space-40) !important;
  }
  $(u)-mr-48 {
    margin-right: var(--space-48) !important;
  }
  $(u)-mr-56 {
    margin-right: var(--space-56) !important;
  }
  $(u)-mr-64 {
    margin-right: var(--space-64) !important;
  }
  $(u)-mb-auto {
    margin-bottom: auto !important;
  }
  $(u)-mb-0 {
    margin-bottom: 0 !important;
  }
  $(u)-mb-px {
    margin-bottom: var(--space-px) !important;
  }
  $(u)-mb-1 {
    margin-bottom: var(--space-1) !important;
  }
  $(u)-mb-2 {
    margin-bottom: var(--space-2) !important;
  }
  $(u)-mb-3 {
    margin-bottom: var(--space-3) !important;
  }
  $(u)-mb-4 {
    margin-bottom: var(--space-4) !important;
  }
  $(u)-mb-5 {
    margin-bottom: var(--space-5) !important;
  }
  $(u)-mb-6 {
    margin-bottom: var(--space-6) !important;
  }
  $(u)-mb-7 {
    margin-bottom: var(--space-7) !important;
  }
  $(u)-mb-8 {
    margin-bottom: var(--space-8) !important;
  }
  $(u)-mb-9 {
    margin-bottom: var(--space-9) !important;
  }
  $(u)-mb-10 {
    margin-bottom: var(--space-10) !important;
  }
  $(u)-mb-12 {
    margin-bottom: var(--space-12) !important;
  }
  $(u)-mb-16 {
    margin-bottom: var(--space-16) !important;
  }
  $(u)-mb-20 {
    margin-bottom: var(--space-20) !important;
  }
  $(u)-mb-24 {
    margin-bottom: var(--space-24) !important;
  }
  $(u)-mb-29 {
    margin-bottom: var(--space-29) !important;
  }
  $(u)-mb-32 {
    margin-bottom: var(--space-32) !important;
  }
  $(u)-mb-40 {
    margin-bottom: var(--space-40) !important;
  }
  $(u)-mb-48 {
    margin-bottom: var(--space-48) !important;
  }
  $(u)-mb-56 {
    margin-bottom: var(--space-56) !important;
  }
  $(u)-mb-64 {
    margin-bottom: var(--space-64) !important;
  }
  $(u)-ml-auto {
    margin-left: auto !important;
  }
  $(u)-ml-0 {
    margin-left: 0 !important;
  }
  $(u)-ml-px {
    margin-left: var(--space-px) !important;
  }
  $(u)-ml-1 {
    margin-left: var(--space-1) !important;
  }
  $(u)-ml-2 {
    margin-left: var(--space-2) !important;
  }
  $(u)-ml-3 {
    margin-left: var(--space-3) !important;
  }
  $(u)-ml-4 {
    margin-left: var(--space-4) !important;
  }
  $(u)-ml-5 {
    margin-left: var(--space-5) !important;
  }
  $(u)-ml-6 {
    margin-left: var(--space-6) !important;
  }
  $(u)-ml-7 {
    margin-left: var(--space-7) !important;
  }
  $(u)-ml-8 {
    margin-left: var(--space-8) !important;
  }
  $(u)-ml-9 {
    margin-left: var(--space-9) !important;
  }
  $(u)-ml-10 {
    margin-left: var(--space-10) !important;
  }
  $(u)-ml-12 {
    margin-left: var(--space-12) !important;
  }
  $(u)-ml-16 {
    margin-left: var(--space-16) !important;
  }
  $(u)-ml-24 {
    margin-left: var(--space-24) !important;
  }
  $(u)-ml-32 {
    margin-left: var(--space-32) !important;
  }
  $(u)-ml-40 {
    margin-left: var(--space-40) !important;
  }
  $(u)-ml-48 {
    margin-left: var(--space-48) !important;
  }
  $(u)-ml-56 {
    margin-left: var(--space-56) !important;
  }
  $(u)-ml-64 {
    margin-left: var(--space-64) !important;
  }
  $(u)-mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  $(u)-mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  $(u)-mx-px {
    margin-right: var(--space-px) !important;
    margin-left: var(--space-px) !important;
  }
  $(u)-mx-1 {
    margin-right: var(--space-1) !important;
    margin-left: var(--space-1) !important;
  }
  $(u)-mx-2 {
    margin-right: var(--space-2) !important;
    margin-left: var(--space-2) !important;
  }
  $(u)-mx-3 {
    margin-right: var(--space-3) !important;
    margin-left: var(--space-3) !important;
  }
  $(u)-mx-4 {
    margin-right: var(--space-4) !important;
    margin-left: var(--space-4) !important;
  }
  $(u)-mx-5 {
    margin-right: var(--space-5) !important;
    margin-left: var(--space-5) !important;
  }
  $(u)-mx-6 {
    margin-right: var(--space-6) !important;
    margin-left: var(--space-6) !important;
  }
  $(u)-mx-7 {
    margin-right: var(--space-7) !important;
    margin-left: var(--space-7) !important;
  }
  $(u)-mx-8 {
    margin-right: var(--space-8) !important;
    margin-left: var(--space-8) !important;
  }
  $(u)-mx-9 {
    margin-right: var(--space-9) !important;
    margin-left: var(--space-9) !important;
  }
  $(u)-mx-10 {
    margin-right: var(--space-10) !important;
    margin-left: var(--space-10) !important;
  }
  $(u)-mx-12 {
    margin-right: var(--space-12) !important;
    margin-left: var(--space-12) !important;
  }
  $(u)-mx-16 {
    margin-right: var(--space-16) !important;
    margin-left: var(--space-16) !important;
  }
  $(u)-mx-24 {
    margin-right: var(--space-24) !important;
    margin-left: var(--space-24) !important;
  }
  $(u)-mx-32 {
    margin-right: var(--space-32) !important;
    margin-left: var(--space-32) !important;
  }
  $(u)-mx-40 {
    margin-right: var(--space-40) !important;
    margin-left: var(--space-40) !important;
  }
  $(u)-mx-48 {
    margin-right: var(--space-48) !important;
    margin-left: var(--space-48) !important;
  }
  $(u)-mx-56 {
    margin-right: var(--space-56) !important;
    margin-left: var(--space-56) !important;
  }
  $(u)-mx-64 {
    margin-right: var(--space-64) !important;
    margin-left: var(--space-64) !important;
  }
  $(u)-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  $(u)-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  $(u)-my-px {
    margin-top: var(--space-px) !important;
    margin-bottom: var(--space-px) !important;
  }
  $(u)-my-1 {
    margin-top: var(--space-1) !important;
    margin-bottom: var(--space-1) !important;
  }
  $(u)-my-2 {
    margin-top: var(--space-2) !important;
    margin-bottom: var(--space-2) !important;
  }
  $(u)-my-3 {
    margin-top: var(--space-3) !important;
    margin-bottom: var(--space-3) !important;
  }
  $(u)-my-4 {
    margin-top: var(--space-4) !important;
    margin-bottom: var(--space-4) !important;
  }
  $(u)-my-5 {
    margin-top: var(--space-5) !important;
    margin-bottom: var(--space-5) !important;
  }
  $(u)-my-6 {
    margin-top: var(--space-6) !important;
    margin-bottom: var(--space-6) !important;
  }
  $(u)-my-7 {
    margin-top: var(--space-7) !important;
    margin-bottom: var(--space-7) !important;
  }
  $(u)-my-8 {
    margin-top: var(--space-8) !important;
    margin-bottom: var(--space-8) !important;
  }
  $(u)-my-9 {
    margin-top: var(--space-9) !important;
    margin-bottom: var(--space-9) !important;
  }
  $(u)-my-10 {
    margin-top: var(--space-10) !important;
    margin-bottom: var(--space-10) !important;
  }
  $(u)-my-12 {
    margin-top: var(--space-12) !important;
    margin-bottom: var(--space-12) !important;
  }
  $(u)-my-16 {
    margin-top: var(--space-16) !important;
    margin-bottom: var(--space-16) !important;
  }
  $(u)-my-20 {
    margin-top: var(--space-20) !important;
    margin-bottom: var(--space-20) !important;
  }
  $(u)-my-24 {
    margin-top: var(--space-24) !important;
    margin-bottom: var(--space-24) !important;
  }
  $(u)-my-29 {
    margin-top: var(--space-29) !important;
    margin-bottom: var(--space-29) !important;
  }
  $(u)-my-32 {
    margin-top: var(--space-32) !important;
    margin-bottom: var(--space-32) !important;
  }
  $(u)-my-40 {
    margin-top: var(--space-40) !important;
    margin-bottom: var(--space-40) !important;
  }
  $(u)-my-48 {
    margin-top: var(--space-48) !important;
    margin-bottom: var(--space-48) !important;
  }
  $(u)-my-56 {
    margin-top: var(--space-56) !important;
    margin-bottom: var(--space-56) !important;
  }
  $(u)-my-64 {
    margin-top: var(--space-64) !important;
    margin-bottom: var(--space-64) !important;
  }
  $(u)-p-auto {
    padding: auto !important;
  }
  $(u)-p-0 {
    padding: 0 !important;
  }
  $(u)-p-px {
    padding: var(--space-px) !important;
  }
  $(u)-p-1 {
    padding: var(--space-1) !important;
  }
  $(u)-p-2 {
    padding: var(--space-2) !important;
  }
  $(u)-p-3 {
    padding: var(--space-3) !important;
  }
  $(u)-p-4 {
    padding: var(--space-4) !important;
  }
  $(u)-p-5 {
    padding: var(--space-5) !important;
  }
  $(u)-p-6 {
    padding: var(--space-6) !important;
  }
  $(u)-p-7 {
    padding: var(--space-7) !important;
  }
  $(u)-p-8 {
    padding: var(--space-8) !important;
  }
  $(u)-p-9 {
    padding: var(--space-9) !important;
  }
  $(u)-p-10 {
    padding: var(--space-10) !important;
  }
  $(u)-p-12 {
    padding: var(--space-12) !important;
  }
  $(u)-p-16 {
    padding: var(--space-16) !important;
  }
  $(u)-p-24 {
    padding: var(--space-24) !important;
  }
  $(u)-p-32 {
    padding: var(--space-32) !important;
  }
  $(u)-p-40 {
    padding: var(--space-40) !important;
  }
  $(u)-p-48 {
    padding: var(--space-48) !important;
  }
  $(u)-p-56 {
    padding: var(--space-56) !important;
  }
  $(u)-p-64 {
    padding: var(--space-64) !important;
  }
  $(u)-pt-auto {
    padding-top: auto !important;
  }
  $(u)-pt-0 {
    padding-top: 0 !important;
  }
  $(u)-pt-px {
    padding-top: var(--space-px) !important;
  }
  $(u)-pt-1 {
    padding-top: var(--space-1) !important;
  }
  $(u)-pt-2 {
    padding-top: var(--space-2) !important;
  }
  $(u)-pt-3 {
    padding-top: var(--space-3) !important;
  }
  $(u)-pt-4 {
    padding-top: var(--space-4) !important;
  }
  $(u)-pt-5 {
    padding-top: var(--space-5) !important;
  }
  $(u)-pt-6 {
    padding-top: var(--space-6) !important;
  }
  $(u)-pt-7 {
    padding-top: var(--space-7) !important;
  }
  $(u)-pt-8 {
    padding-top: var(--space-8) !important;
  }
  $(u)-pt-9 {
    padding-top: var(--space-9) !important;
  }
  $(u)-pt-10 {
    padding-top: var(--space-10) !important;
  }
  $(u)-pt-12 {
    padding-top: var(--space-12) !important;
  }
  $(u)-pt-14 {
    padding-top: var(--space-14) !important;
  }
  $(u)-pt-16 {
    padding-top: var(--space-16) !important;
  }
  $(u)-pt-20 {
    padding-top: var(--space-20) !important;
  }
  $(u)-pt-24 {
    padding-top: var(--space-24) !important;
  }
  $(u)-pt-32 {
    padding-top: var(--space-32) !important;
  }
  $(u)-pt-40 {
    padding-top: var(--space-40) !important;
  }
  $(u)-pt-48 {
    padding-top: var(--space-48) !important;
  }
  $(u)-pt-56 {
    padding-top: var(--space-56) !important;
  }
  $(u)-pt-64 {
    padding-top: var(--space-64) !important;
  }
  $(u)-pr-auto {
    padding-right: auto !important;
  }
  $(u)-pr-0 {
    padding-right: 0 !important;
  }
  $(u)-pr-px {
    padding-right: var(--space-px) !important;
  }
  $(u)-pr-1 {
    padding-right: var(--space-1) !important;
  }
  $(u)-pr-2 {
    padding-right: var(--space-2) !important;
  }
  $(u)-pr-3 {
    padding-right: var(--space-3) !important;
  }
  $(u)-pr-4 {
    padding-right: var(--space-4) !important;
  }
  $(u)-pr-5 {
    padding-right: var(--space-5) !important;
  }
  $(u)-pr-6 {
    padding-right: var(--space-6) !important;
  }
  $(u)-pr-7 {
    padding-right: var(--space-7) !important;
  }
  $(u)-pr-8 {
    padding-right: var(--space-8) !important;
  }
  $(u)-pr-9 {
    padding-right: var(--space-9) !important;
  }
  $(u)-pr-10 {
    padding-right: var(--space-10) !important;
  }
  $(u)-pr-12 {
    padding-right: var(--space-12) !important;
  }
  $(u)-pr-16 {
    padding-right: var(--space-16) !important;
  }
  $(u)-pr-24 {
    padding-right: var(--space-24) !important;
  }
  $(u)-pr-32 {
    padding-right: var(--space-32) !important;
  }
  $(u)-pr-40 {
    padding-right: var(--space-40) !important;
  }
  $(u)-pr-48 {
    padding-right: var(--space-48) !important;
  }
  $(u)-pr-56 {
    padding-right: var(--space-56) !important;
  }
  $(u)-pr-64 {
    padding-right: var(--space-64) !important;
  }
  $(u)-pb-auto {
    padding-bottom: auto !important;
  }
  $(u)-pb-0 {
    padding-bottom: 0 !important;
  }
  $(u)-pb-px {
    padding-bottom: var(--space-px) !important;
  }
  $(u)-pb-1 {
    padding-bottom: var(--space-1) !important;
  }
  $(u)-pb-2 {
    padding-bottom: var(--space-2) !important;
  }
  $(u)-pb-3 {
    padding-bottom: var(--space-3) !important;
  }
  $(u)-pb-4 {
    padding-bottom: var(--space-4) !important;
  }
  $(u)-pb-5 {
    padding-bottom: var(--space-5) !important;
  }
  $(u)-pb-6 {
    padding-bottom: var(--space-6) !important;
  }
  $(u)-pb-7 {
    padding-bottom: var(--space-7) !important;
  }
  $(u)-pb-8 {
    padding-bottom: var(--space-8) !important;
  }
  $(u)-pb-9 {
    padding-bottom: var(--space-9) !important;
  }
  $(u)-pb-10 {
    padding-bottom: var(--space-10) !important;
  }
  $(u)-pb-12 {
    padding-bottom: var(--space-12) !important;
  }
  $(u)-pb-16 {
    padding-bottom: var(--space-16) !important;
  }
  $(u)-pb-24 {
    padding-bottom: var(--space-24) !important;
  }
  $(u)-pb-25 {
    padding-bottom: var(--space-25) !important;
  }
  $(u)-pb-29 {
    padding-bottom: var(--space-29) !important;
  }
  $(u)-pb-32 {
    padding-bottom: var(--space-32) !important;
  }
  $(u)-pb-40 {
    padding-bottom: var(--space-40) !important;
  }
  $(u)-pb-48 {
    padding-bottom: var(--space-48) !important;
  }
  $(u)-pb-56 {
    padding-bottom: var(--space-56) !important;
  }
  $(u)-pb-64 {
    padding-bottom: var(--space-64) !important;
  }
  $(u)-pl-auto {
    padding-left: auto !important;
  }
  $(u)-pl-0 {
    padding-left: 0 !important;
  }
  $(u)-pl-px {
    padding-left: var(--space-px) !important;
  }
  $(u)-pl-1 {
    padding-left: var(--space-1) !important;
  }
  $(u)-pl-2 {
    padding-left: var(--space-2) !important;
  }
  $(u)-pl-3 {
    padding-left: var(--space-3) !important;
  }
  $(u)-pl-4 {
    padding-left: var(--space-4) !important;
  }
  $(u)-pl-5 {
    padding-left: var(--space-5) !important;
  }
  $(u)-pl-6 {
    padding-left: var(--space-6) !important;
  }
  $(u)-pl-7 {
    padding-left: var(--space-7) !important;
  }
  $(u)-pl-8 {
    padding-left: var(--space-8) !important;
  }
  $(u)-pl-9 {
    padding-left: var(--space-9) !important;
  }
  $(u)-pl-10 {
    padding-left: var(--space-10) !important;
  }
  $(u)-pl-12 {
    padding-left: var(--space-12) !important;
  }
  $(u)-pl-16 {
    padding-left: var(--space-16) !important;
  }
  $(u)-pl-24 {
    padding-left: var(--space-24) !important;
  }
  $(u)-pl-32 {
    padding-left: var(--space-32) !important;
  }
  $(u)-pl-40 {
    padding-left: var(--space-40) !important;
  }
  $(u)-pl-48 {
    padding-left: var(--space-48) !important;
  }
  $(u)-pl-56 {
    padding-left: var(--space-56) !important;
  }
  $(u)-pl-64 {
    padding-left: var(--space-64) !important;
  }
  $(u)-px-auto {
    padding-right: auto !important;
    padding-left: auto !important;
  }
  $(u)-px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  $(u)-px-px {
    padding-right: var(--space-px) !important;
    padding-left: var(--space-px) !important;
  }
  $(u)-px-1 {
    padding-right: var(--space-1) !important;
    padding-left: var(--space-1) !important;
  }
  $(u)-px-2 {
    padding-right: var(--space-2) !important;
    padding-left: var(--space-2) !important;
  }
  $(u)-px-3 {
    padding-right: var(--space-3) !important;
    padding-left: var(--space-3) !important;
  }
  $(u)-px-4 {
    padding-right: var(--space-4) !important;
    padding-left: var(--space-4) !important;
  }
  $(u)-px-5 {
    padding-right: var(--space-5) !important;
    padding-left: var(--space-5) !important;
  }
  $(u)-px-6 {
    padding-right: var(--space-6) !important;
    padding-left: var(--space-6) !important;
  }
  $(u)-px-7 {
    padding-right: var(--space-7) !important;
    padding-left: var(--space-7) !important;
  }
  $(u)-px-8 {
    padding-right: var(--space-8) !important;
    padding-left: var(--space-8) !important;
  }
  $(u)-px-9 {
    padding-right: var(--space-9) !important;
    padding-left: var(--space-9) !important;
  }
  $(u)-px-10 {
    padding-right: var(--space-10) !important;
    padding-left: var(--space-10) !important;
  }
  $(u)-px-12 {
    padding-right: var(--space-12) !important;
    padding-left: var(--space-12) !important;
  }
  $(u)-px-14 {
    padding-right: var(--space-14) !important;
    padding-left: var(--space-14) !important;
  }
  $(u)-px-16 {
    padding-right: var(--space-16) !important;
    padding-left: var(--space-16) !important;
  }
  $(u)-px-24 {
    padding-right: var(--space-24) !important;
    padding-left: var(--space-24) !important;
  }
  $(u)-px-32 {
    padding-right: var(--space-32) !important;
    padding-left: var(--space-32) !important;
  }
  $(u)-px-40 {
    padding-right: var(--space-40) !important;
    padding-left: var(--space-40) !important;
  }
  $(u)-px-48 {
    padding-right: var(--space-48) !important;
    padding-left: var(--space-48) !important;
  }
  $(u)-px-56 {
    padding-right: var(--space-56) !important;
    padding-left: var(--space-56) !important;
  }
  $(u)-px-64 {
    padding-right: var(--space-64) !important;
    padding-left: var(--space-64) !important;
  }
  $(u)-py-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  $(u)-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  $(u)-py-px {
    padding-top: var(--space-px) !important;
    padding-bottom: var(--space-px) !important;
  }
  $(u)-py-1 {
    padding-top: var(--space-1) !important;
    padding-bottom: var(--space-1) !important;
  }
  $(u)-py-2 {
    padding-top: var(--space-2) !important;
    padding-bottom: var(--space-2) !important;
  }
  $(u)-py-3 {
    padding-top: var(--space-3) !important;
    padding-bottom: var(--space-3) !important;
  }
  $(u)-py-4 {
    padding-top: var(--space-4) !important;
    padding-bottom: var(--space-4) !important;
  }
  $(u)-py-5 {
    padding-top: var(--space-5) !important;
    padding-bottom: var(--space-5) !important;
  }
  $(u)-py-6 {
    padding-top: var(--space-6) !important;
    padding-bottom: var(--space-6) !important;
  }
  $(u)-py-7 {
    padding-top: var(--space-7) !important;
    padding-bottom: var(--space-7) !important;
  }
  $(u)-py-8 {
    padding-top: var(--space-8) !important;
    padding-bottom: var(--space-8) !important;
  }
  $(u)-py-9 {
    padding-top: var(--space-9) !important;
    padding-bottom: var(--space-9) !important;
  }
  $(u)-py-10 {
    padding-top: var(--space-10) !important;
    padding-bottom: var(--space-10) !important;
  }
  $(u)-py-12 {
    padding-top: var(--space-12) !important;
    padding-bottom: var(--space-12) !important;
  }
  $(u)-py-14 {
    padding-top: var(--space-14) !important;
    padding-bottom: var(--space-14) !important;
  }
  $(u)-py-16 {
    padding-top: var(--space-16) !important;
    padding-bottom: var(--space-16) !important;
  }
  $(u)-py-20 {
    padding-top: var(--space-20) !important;
    padding-bottom: var(--space-20) !important;
  }
  $(u)-py-24 {
    padding-top: var(--space-24) !important;
    padding-bottom: var(--space-24) !important;
  }
  $(u)-py-25 {
    padding-top: var(--space-25) !important;
    padding-bottom: var(--space-25) !important;
  }
  $(u)-py-29 {
    padding-top: var(--space-29) !important;
    padding-bottom: var(--space-29) !important;
  }
  $(u)-py-32 {
    padding-top: var(--space-32) !important;
    padding-bottom: var(--space-32) !important;
  }
  $(u)-py-40 {
    padding-top: var(--space-40) !important;
    padding-bottom: var(--space-40) !important;
  }
  $(u)-py-48 {
    padding-top: var(--space-48) !important;
    padding-bottom: var(--space-48) !important;
  }
  $(u)-py-56 {
    padding-top: var(--space-56) !important;
    padding-bottom: var(--space-56) !important;
  }
  $(u)-py-64 {
    padding-top: var(--space-64) !important;
    padding-bottom: var(--space-64) !important;
  }
}

@mixin u-spacer .u;
@media (--to-md) {
  /* prettier-ignore */
  @mixin u-spacer .s\:u;
}
@media (--md) {
  /* prettier-ignore */
  @mixin u-spacer .m\:u;
}
@media (--lg) {
  /* prettier-ignore */
  @mixin u-spacer .l\:u;
}
/*@media (--large) {
  @mixin u-spacer .xl\:u;
}*/

/*
u-p(m)-xxのcssはjsから生成

var type = ["margin", "padding"];
var typeShort = ["m", "p"];
var direction = {
  a: [""],
  t: ["top"],
  r: ["right"],
  b: ["bottom"],
  l: ["left"],
  x: ["right", "left"],
  y: ["top", "bottom"],
};

var value = [
  ["auto", "auto"],
  ["0", "0"],
  ["px", "var(--space-px)"],
  ["1", "var(--space-1)"],
  ["2", "var(--space-2)"],
  ["3", "var(--space-3)"],
  ["4", "var(--space-4)"],
  ["5", "var(--space-5)"],
  ["6", "var(--space-6)"],
  ["7", "var(--space-7)"],
  ["8", "var(--space-8)"],
  ["9", "var(--space-9)"],
  ["10", "var(--space-10)"],
  ["12", "var(--space-12)"],
  ["16", "var(--space-16)"],
  ["24", "var(--space-24)"],
  ["32", "var(--space-32)"],
  ["40", "var(--space-40)"],
  ["48", "var(--space-48)"],
  ["56", "var(--space-56)"],
  ["64", "var(--space-64)"],
];

var list = [];

type.forEach((typeName, typeIndex) => {
  Object.keys(direction).forEach((keys) => {
    var classkeys = keys === "a" ? "" : keys;

    // normal
    value.forEach((valueData) => {
      var css = "";
      css += `$(u)-${typeShort[typeIndex]}${classkeys}-${valueData[0]} {`;

      direction[keys].forEach((keysName) => {
        if (keysName) {
          css += `${typeName}-${keysName}: ${valueData[1]} !important;`;
        } else {
          css += `${typeName}: ${valueData[1]} !important;`;
        }
      });

      css += `}\n`;

      list.push(css);
    });
  });
});

console.log(list.join(""));

*/
