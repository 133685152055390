@media print {
  * {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre,
  blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 2cm 0.5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  select {
    background: var(--color-white) !important;
  }

  /* 個別 */
  .l-header,
  .l-footer,
  .l-main__footer {
    display: none !important;
  }
  .p-sub-nav,
  .p-fourth-nav,
  .p-inpage-links {
    display: none !important;
  }

  .l-main__header,
  .l-main__body,
  .l-main__contents {
    padding: 0 !important;
  }
  .l-section,
  section.l-wrapper {
    margin-top: 20px !important;
  }
  .l-container + .l-container {
    margin-top: 0;
  }

  .c-title {
    &._h2 {
      font-size: 2.2rem !important;
    }
    &._h3 {
      font-size: 2rem !important;
    }
    &._h4 {
      font-size: 1.8rem !important;
    }
    &._h5 {
      font-size: 1.6rem !important;
    }
  }
}
