.u-border {
  border: 1px solid var(--color-border) !important;
}
.u-border-light {
  border: 1px solid var(--color-border-light) !important;
}
.u-border-dark {
  border: 1px solid var(--color-border-dark) !important;
}
.u-border-no {
  border: none !important;
}
