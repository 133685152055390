.p-header-logo {
  display: inline-flex;
  align-items: center;
}

.p-header-logo__item {
  margin: 0;
  font-weight: var(--font-bold);
  font-size: var(--20px);
  & a {
    display: block;
    color: var(--color-type);
    transition: opacity 0.3s;
    @mixin onHoverFocus {
      opacity: var(--opacity);
    }
  }
}
