.c-table {
  width: 100%;
  line-height: var(--line-height-sm);
  table-layout: fixed;
  background: var(--color-white);
  border-collapse: collapse;
  border-spacing: 0;

  & tr > th,
  & tr > td {
    padding: 10px;
    font-weight: var(--font-normal);
    text-align: left;
    vertical-align: top;
    word-break: break-all;
    border: 1px solid var(--color-border-light);

    @media (--md) {
      padding: 12px 16px;
    }
  }

  & thead {
    & th,
    & td {
    }
  }

  & tr > th {
    color: var(--color-type);
    font-weight: var(--font-bold);
    background-color: var(--color-main-20);
  }

  & th:last-child,
  & td:last-child {
    border-right: none;
  }
  & th:first-child,
  & td:first-child {
    border-left: none;
  }

  &._slim {
    & th,
    & td,
    & tr {
      padding: 6px;
    }
  }

  &._borderless {
    border: none !important;
    & th,
    & td,
    & tr {
      border: none !important;
    }
  }

  &._text-center {
    & th,
    & td {
      text-align: center !important;
    }
  }

  & caption {
    margin-bottom: 4px;
    font-weight: var(--font-bold);
    font-size: var(--text-lg);
    text-align: left;
  }

  & + figcaption {
    margin-top: 6px;
    font-size: var(--text-xs);
  }
  &._none {
    background: var(--color-gray-10);
    & tr > th {
      width: 120px;
      font-weight: normal;
      background-color: transparent;
      @media (--to-md) {
        width: 110px;
      }
    }
  }
}

.c-table__outer {
  &._sp-scroll {
    @media (--to-md) {
      padding: 0 1px 0 0;
      overflow: hidden;
      overflow-x: auto;
      & .c-table {
        width: 800px;
      }
    }
  }

  &._sp-scroll-large {
    @media (--to-md) {
      padding: 0 1px 0 0;
      overflow: hidden;
      overflow-x: auto;
      & .c-table {
        width: 1200px;
      }
    }
  }
}
