@define-mixin onHoverFocus {
  &.focus-visible:focus,
  &:focus-visible {
    @mixin-content;
  }
  @media (hover: hover) {
    &:hover {
      @mixin-content;
    }
  }
}
