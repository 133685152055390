.p-header-hamburger {
  display: block;
  @media (--lg) {
    display: none;
  }
}

.p-header-hamburger__container {
  position: absolute;
  top: 0;
  right: 15px;
  width: 48px;
  height: 100%;

  @media (--lg) {
    display: none;
  }
}

.p-header-hamburger__button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: visible;
  font-weight: bold;
  font-size: var(--11px);
  text-align: center;
  vertical-align: middle;
  background-color: var(--color-white);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  transition: opacity 0.3s;
  appearance: none;

  @mixin onHoverFocus {
    opacity: var(--opacity);
  }

  &[aria-expanded="true"] {
    z-index: 1010;
  }

  & .txt {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
  }

  & .open {
    display: block;
  }
  & .close {
    display: none;
  }

  &.is-active {
    & .open {
      display: none;
    }
    & .close {
      display: block;
    }
  }

  & .line {
    position: relative;
    display: block;
    /* width: 30%;
      height: 30px; */

    @media (--to-lg) {
      width: 24px;
      height: 15px;
    }

    & > span {
      position: absolute;
      left: 0;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 2px;
      margin: auto;

      &:before {
        display: block;
        width: 100%;
        height: 100%;
        background: var(--color-gray-60);
        content: "";
      }
    }

    & > span:nth-child(1) {
      top: 0;
      transition: transform 0.3s var(--ease), top 0.3s var(--ease);
    }

    & > span:nth-child(2) {
      top: 2px;
      bottom: 0;
      transition: opacity 0.3s;
    }

    & > span:nth-child(3) {
      top: 15px;
      transition: transform 0.3s var(--ease), top 0.3s var(--ease);
    }
  }

  &.is-active {
    & .line {
      & > span:nth-child(1) {
        top: 7.5px;
        transform: rotate(30deg);
      }
      & > span:nth-child(2) {
        opacity: 0;
      }
      & > span:nth-child(3) {
        top: 7.5px;
        transform: rotate(-30deg);
      }
    }
  }
}
