.p-tab {
}

.p-tabs {
  & [role="tablist"] {
  }

  & [role="tab"],
  & [role="tab"]:focus,
  & [role="tab"]:hover {
  }

  & [role="tab"][aria-selected="true"] {
  }

  & [role="tab"][aria-selected="false"] {
  }

  & [role="tab"] {
  }

  & [role="tab"]:hover,
  & [role="tab"]:focus,
  & [role="tab"]:active {
  }

  & [role="tabpanel"] {
  }

  & [role="tabpanel"][hidden] {
    display: none;
  }

  &._vertical {
    display: flex;

    & .p-tabs__head {
      width: 20%;
    }
    & .p-tabs__contents {
      flex: 1;
      border-top: 1px solid var(--color-border);
    }
    & .p-tabs__btn-items {
      position: sticky;
      top: 0;
      flex-direction: column;
      margin-right: 8px;
      &::after {
        display: none;
      }
    }
    & .p-tabs__btn[aria-selected="true"] {
      border-bottom: 1px solid var(--color-border);
    }
  }
}

.p-tabs__head {
  position: relative;
}
.p-tabs__btn-items {
  position: relative;
  display: flex;
  /* margin-bottom: -1px; */

  @media (--md) {
  }

  &::after {
    flex: 1;
    border-bottom: 1px solid var(--color-border);
    content: "";
  }

  &._fit {
    justify-content: space-between;

    &::after {
      display: none;
    }

    & .p-tabs__btn-item {
      flex: 1 1 0%;
      min-width: 0;
    }
  }
}

.p-tabs__btn-item {
  position: relative;
  display: flex;
  padding: 0;
}

.p-tabs__btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 5px;
  overflow: visible;
  color: var(--color-type);
  font-weight: bold;
  font-size: var(--16px);
  line-height: var(--line-height-xs);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  word-break: break-word;
  background: #f8f8f8;
  border: 1px solid var(--color-border);
  border-radius: 0;
  cursor: pointer;

  @media (--md) {
    padding: 20px 10px;
  }

  &[aria-selected="true"] {
    color: var(--color-main);
    background: #fff;
    border-bottom: 1px solid transparent;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: var(--color-main);
      content: "";
    }
  }

  @mixin onHoverFocus {
    color: var(--color-main);
    text-decoration: none;
    background: var(--color-main-20);

    &[aria-selected="true"] {
      background: #fff;
    }
  }

  &[disabled] {
    color: var(--color-gray-40);
    background: var(--color-gray-30);
    cursor: default;

    @mixin onHoverFocus {
      color: var(--color-gray-40);
      background: var(--color-gray-30);
      pointer-events: none;
    }
  }
}

.p-tabs__content {
  padding: 32px;
}

.p-tabs__contents {
  border: 1px solid var(--color-border);
  border-top: none;
}
