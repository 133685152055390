@define-mixin u-wh $u {
  $(u)-w-auto {
    width: auto !important;
  }
  $(u)-w-full {
    width: 100% !important;
  }
  $(u)-w-screen {
    width: 100vw !important;
  }
  $(u)-min-w-0 {
    min-width: 0 !important;
  }
  $(u)-min-w-full {
    min-width: 100% !important;
  }

  $(u)-max-w-xs {
    max-width: 240px !important;
  }
  $(u)-max-w-sm {
    max-width: 375px !important;
  }
  $(u)-max-w-smd {
    max-width: 480px !important;
  }
  $(u)-max-w-md {
    max-width: 768px !important;
  }
  $(u)-max-w-lg {
    max-width: 1000px !important;
  }
  $(u)-max-w-xl {
    max-width: 1200px !important;
  }
  $(u)-max-w-full {
    max-width: 100% !important;
  }

  $(u)-w-1\/2 {
    width: 50% !important;
  }
  $(u)-w-1\/3 {
    width: 33.33333% !important;
  }
  $(u)-w-2\/3 {
    width: 66.66666% !important;
  }
  $(u)-w-1\/4 {
    width: 25% !important;
  }
  $(u)-w-2\/4 {
    width: 50% !important;
  }
  $(u)-w-3\/4 {
    width: 75% !important;
  }
  $(u)-w-90 {
    width: 90% !important;
  }

  $(u)-h-full {
    height: 100% !important;
  }
  $(u)-min-h-0 {
    min-height: 0 !important;
  }
  $(u)-min-h-50 {
    min-height: 50% !important;
  }
  $(u)-min-h-full {
    min-height: 100% !important;
  }
  $(u)-min-h-screen {
    min-height: 100vh !important;
  }
}

@mixin u-wh .u;
@media (--to-md) {
  /* prettier-ignore */
  @mixin u-wh .s\:u;
}
@media (--md) {
  /* prettier-ignore */
  @mixin u-wh .m\:u;
}
@media (--lg) {
  /* prettier-ignore */
  @mixin u-wh .l\:u;
}
/*@media (--large) {
  @mixin u-wh .xl\:u;
}*/
