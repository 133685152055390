.p-header-main {
  display: none;
  text-align: left;

  @nest .js-header_toggle-target[aria-hidden="false"] & {
    display: block;
  }
  @media (--lg) {
    display: block;
    flex: 1 1 0%;
  }
}

.p-header-main__language-switcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 5vw;
  @media (--lg) {
    display: none;
  }
}

.p-header-main__inner {
  @media (--to-lg) {
    margin-bottom: 30px;
  }

  @media (--lg) {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 100%;
  }
}

.p-header-main__contact {
  margin-top: 30px;
  padding: 15px 5vw;
  text-align: center;
}
