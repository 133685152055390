@define-mixin l-content $responsive {
  $(responsive)-content-narrow {
    max-width: 740px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
  $(responsive)-content-narrow-s {
    max-width: 840px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
  $(responsive)-content-narrow-m {
    max-width: 960px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
  $(responsive)-content-narrow-l {
    max-width: 1200px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  $(responsive)-content-full {
    width: auto !important;
    margin-right: var(--contentfull-margin) !important;
    margin-left: var(--contentfull-margin) !important;
    padding-right: var(--contentfull-padding) !important;
    padding-left: var(--contentfull-padding) !important;
  }
  $(responsive)-content-full-inner {
    width: auto !important;
    margin-right: var(--contentfull-margin) !important;
    margin-left: var(--contentfull-margin) !important;
  }

  $(responsive)-content-full-r {
    width: auto !important;
    margin-right: var(--contentfull-margin) !important;
    margin-left: 0 !important;
    padding-right: var(--contentfull-padding) !important;
    padding-left: 0 !important;
  }
  $(responsive)-content-full-l {
    width: auto !important;
    margin-right: 0 !important;
    margin-left: var(--contentfull-margin) !important;
    padding-right: 0 !important;
    padding-left: var(--contentfull-padding) !important;
  }
  $(responsive)-content-full-inner-r {
    width: auto !important;
    margin-right: var(--contentfull-margin) !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  $(responsive)-content-full-inner-l {
    width: auto !important;
    margin-right: 0 !important;
    margin-left: var(--contentfull-margin) !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@mixin l-content .l;
@media (--to-md) {
  /* prettier-ignore */
  @mixin l-content .s\:l;
}
@media (--md) {
  /* prettier-ignore */
  @mixin l-content .m\:l;
}
@media (--lg) {
  /* prettier-ignore */
  @mixin l-content .l\:l;
}
