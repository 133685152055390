.u-hide {
  display: none !important;
}

.u-hide-s,
.u-hide-s-down {
  @media (--to-md) {
    display: none !important;
  }
}

.u-hide-m-down {
  @media (--to-lg) {
    display: none !important;
  }
}

.u-hide-m-up {
  @media (--md) {
    display: none !important;
  }
}

.u-hide-m {
  @media (--md) {
    display: none !important;
  }
}

.u-hide-l {
  @media (--lg) {
    display: none !important;
  }
}

.u-show {
  display: block !important;
}

.u-show-l {
  @media (--lg) {
    display: block !important;
  }
}

.u-show-m {
  @media (--md) {
    display: block !important;
  }
}

.u-show-s {
  @media (--to-md) {
    display: block !important;
  }
}

.u-show-m-up {
  @media (--md) {
    display: block !important;
  }
}

.u-show-m-down {
  @media (--to-lg) {
    display: block !important;
  }
}

@define-mixin u-hide $responsive {
  $(responsive)-hide {
    display: none !important;
  }
}
@define-mixin u-show $responsive {
  $(responsive)-show {
    display: block !important;
  }
}

@mixin u-hide .u;
@mixin u-show .u;

@media (--to-md) {
  /* prettier-ignore */
  @mixin u-hide .s\:u;
  /* prettier-ignore */
  @mixin u-show .s\:u;
}
@media (--md) {
  /* prettier-ignore */
  @mixin u-hide .m\:u;
  /* prettier-ignore */
  @mixin u-show .m\:u;
}
@media (--lg) {
  /* prettier-ignore */
  @mixin u-hide .l\:u;
  /* prettier-ignore */
  @mixin u-show .l\:u;
}
/*@media (--large) {
  @mixin u-hide .xl\:u;
  @mixin u-show .xl\:u;
}*/
